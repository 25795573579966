.recent-feed {
  width: 100%;
  justify-content: center;
  display: flex;

}

.recent-feed-line-2 {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 6px;
  top: 2px;
  position: relative;
}

.recent-feed-mobile {
  width: 89%;
  justify-content: center;
  display: flex;
  position: relative;
  right: 5%;
}

.sss {
  fill: #37A09D;
}

.ttt {
  color: #37A09D;
  position: relative;
  bottom: 0px;
  width: 100%;
  cursor: pointer;
}

.recent-feed-title {
  position: relative;
  height: 26px;
  width: 95%;
  justify-content: center;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  align-items: center;
  text-align: left;
  left: 39px;
  cursor: pointer;
}


.recent-feed-title-mobile {
  width: unset;
}

.not-connect {
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 25px;
  line-height: 40px;
}

.recent-feed-person {
  position: absolute;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  top: 45px;
  right: 75px;
  color: #38a09d;
}

.loading-indicator-loadingsvg1 {
  position: relative;
  display: flex;
  top: 100px;
  right: 50%;
  margin: auto;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: scale(4);
}

.recent-feed-icon {
  position: relative;
  width: 100%;
  z-index: 999;
}

.recent-feed-icon-main {
  background-color: #f2f2f2eb;
  height: 70px;
  width: 70px;
  border-top-left-radius: 50%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-bottom-style: solid;
  border-left-style: solid;
  border-bottom-color: rgb(219, 226, 222);
  border-left-color: rgb(219, 226, 222);
  display: flex;
  text-align: center;
  align-items: center;
  position: absolute;
  right: -1px;
  top: -1px;
  z-index: 9999;
}

.recent-feed-icon-mobile {
  position: relative;
  width: 55px;
  top: 10px;
  right: 0px;
}

.recent-feed-d {
  display: flex;
  position: relative;
  height: 45px;
}

.recent-feed-d-mobile {
  display: flex;
  position: relative;
  justify-content: right;
}

.recent-feed-sub {
  display: flex;
  top: 17px;
  position: relative;
}

.recent-feed-person-sub {
  justify-content: center;
  position: relative;
  padding-top: 10px;
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  color: #38A09D;
  width: 297px;
}

.recent-feed-date {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  color: #38A09D;
  position: relative;
  text-align: left;
  cursor: pointer;

  top: 18px;
  position: absolute;
  left: 73px;
}

.recent-feed-date-mobile {
  width: unset;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #656565;
  position: relative;
  top: -4px;
}

.recent-feed-date-sub {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  position: relative;
  align-items: center;
  text-align: center;
  color: #38A09D;
  mix-blend-mode: normal;
  right: 374px;
  top: 7px;
  height: 50px;
}

.recent-feed-star {
  padding-top: 10px;
  padding-right: 707px;
  position: relative;
}

.recent-feed-main-item {
  position: relative;
  width: 728px;
  height: 190px;
  top: 3px;
  background: #FFFFFF;
  border: 0.5px solid #37A09D;
  box-sizing: border-box;
  border-radius: 18px;
}

.recent-feed-main-item-open {
  width: 728px;
  min-height: 200px;
  height: auto;
  background: #FFFFFF;
  margin: 2px;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 200;
  position: relative;
  border-width: 1.5px;
  border-style: solid;
  border-color: rgb(219, 226, 222);
}

.recent-feed-main-item-icon {
  position: relative;
  width: 728px;
  height: 220px;
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  margin: 2px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 101;
}

.recent-feed-main-item-icon-text {
  position: absolute;
  width: 60%;
  text-align: center;
  top: 70px;
  font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 45px;
  color: #656565;
}

.recent-feed-main-item-open-mobile {
  width: 95.5%;
}

.flex {
  display: flex;
}

.flex-z {
  display: flex;
  z-index: 10;
}

.recent-feed-line {
  position: relative;
  width: 579px;
  height: 0px;
  right: 77px;
  border: 0.75px solid #DBE2DE;
}

.recent-feed-line-b {
  position: relative;
  width: 579px;
  height: 0px;
  right: 77px;
  top: 15px;
  border: 0.75px solid #DBE2DE;
}

.recent-feed-line-b-hi {
  height: 27px;
}

.recent-feed-like {
  position: relative;
  right: 38px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  z-index: 100;
}

.recent-feed-like-sub {
  position: relative;
  right: 3px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  z-index: 100;
}

.recent-feed-like-text {
  position: relative;
  right: 45px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
  cursor: pointer;
}

.recent-feed-like-text-mobile {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  right: 14px;
}

.recent-feed-like-text-sub-mobile {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  right: 15px;
}

.recent-feed-like-count {
  position: relative;
  right: 50px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
}

.recent-feed-like-count-mobile {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.recent-feed-text {
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  align-items: center;
  text-align: justify;
  color: #494949;
  width: 581px;
  right: 75px;
  min-height: 116px;
  white-space: pre-wrap;
}

.recent-feed-text br {
  content: ' ';
  position: absolute;
}

.recent-feed-text-mobile {
  width: 86%;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  display: flex;
  align-items: flex-end;
  right: 14%;
  min-height: 79px;
}

.recent-feed-text.ddd {
  top: 20px;
}

.recent-feed-text-sub {
  position: relative;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  align-items: center;
  text-align: right;
  color: #494949;
  overflow-y: scroll;
  width: 652px;
  right: 36px;
  min-height: 10px;
}

.recent-feed-comment {
  position: relative;
  right: 70px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
}

.recent-feed-comment-mobile {
  right: 30px;
}

.recent-feed-comment-text {
  position: relative;
  right: 80px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
  cursor: pointer;
  width: 40px;
}

.recent-feed-comment-text-mobile {
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 10%;
}

.recent-feed-comment-text-sub-mobile {
  right: 50px;
}

.recent-feed-comment-count {
  position: relative;
  right: 91px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
}

.recent-feed-comment-count-mobile {
  position: relative;
  right: 95px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
}

.recent-feed-read-more {
  position: relative;
  right: 620px;
  top: 160px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
}

.ererer {
  height: 57px;
  display: flex;
}

.recent-feed-reply {
  outline: none;
  position: relative;
  right: 20px;
  width: 520px;
  top: 4px;
  font-size: 21px;
}

.recent-feed-reply-mobile {
  outline: none;
  position: relative;
  right: 20px;
  width: 100%;
  top: 4px;
  font-size: 21px;
}

.recent-feed-reply:empty {
  font-size: 21px;
  color: #B7C0C0;
}

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-text)
}

.recent-feed-reply-send {
  top: 10px;
  position: relative;
  font-family: Heebo;
  width: 93.25px;
  height: 37px;
  background: #38A09D;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  color: #FFFFFF;
  align-items: center;
  text-align: center;
  right: 500px;
  cursor: pointer;
}

.recent-feed-reply-send-cancel {
  top: 10px;
  position: relative;
  font-family: Heebo;
  width: 93.25px;
  height: 37px;
  background: #38A09D;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  color: #FFFFFF;
  align-items: center;
  text-align: center;
  right: 450px;
  cursor: pointer;
}

.recent-feed-reply-send:hover {
  background-color: #e4e4e4;
  color: #38A09D;
}

.recent-feed-reply-send-text {
  padding-top: 5px;
}

.none {
  display: none;
}

.recent-feed-reply-h {
  min-width: 60px;
  position: relative;
  width: 573px;
  min-height: 35px;
  right: 78px;
  border: 0.5px solid #DBE2DE;
  border-radius: 8px;
  z-index: 1;
  resize: inherit;
  color: 0.5px solid #DBE2DE;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  outline: none;
}

.recent-feed-reply-h-mobile {
  min-width: 60px;
  position: relative;
  width: 100%;
  min-height: 35px;
  right: 0px;
  border: 0.5px solid #DBE2DE;
  border-radius: 8px;
  z-index: 1;
  resize: inherit;
  color: 0.5px solid #DBE2DE;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  outline: none;
  top: 13px;
}

.recent-feed-reply-h-2 {
  min-width: 60px;
  position: relative;
  width: 522px;
  min-height: 35px;
  border: 0.5px solid #DBE2DE;
  border-radius: 8px;
  z-index: 1;
  resize: inherit;
  color: 0.5px solid #DBE2DE;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  outline: none;
  top: 10px;
}

.recent-feed-reply-h-2-mobile {
  min-width: 60px;
  position: relative;
  width: 100%;
  min-height: 35px;
  border: 0.5px solid #DBE2DE;
  border-radius: 8px;
  z-index: 1;
  resize: inherit;
  color: 0.5px solid #DBE2DE;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 26px;
  outline: none;
  top: 10px;
}

/******************************************************************/
.InfiniteScroll-list {
  width: 100%;
  height: 100%;
  position: relative;
  top: 47px;
}

.InfiniteScroll-list-my-topics {
  width: 100%;
  height: 100%;
  position: relative;
}


.header-openPid {
  position: absolute;
  width: 100%;
  height: 84%;
  background-color: #f2f2f2eb;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  top: 112px;
  overflow: auto;
  z-index: 150;
  justify-content: center;
}

.header-openPid::-webkit-scrollbar {
  width: 15px;
  height: 1px;
  background-color: #e5e7e7;

}

.header-openPid::-webkit-scrollbar-thumb {
  background: #798383;

}

.pageId {
  top: 57px;
  height: 91%;
  position: fixed;
}

.header-openPid1 {
  position: absolute;
  width: 100%;
  height: 84%;
  background-color: #ebebebea;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  display: block;
  top: 112px;
  overflow: auto;
  z-index: 150;
  justify-content: center;
}

.header-openPid1::-webkit-scrollbar {
  width: 15px;
  height: 1px;
  background-color: #e5e7e7;
}

.header-openPid1::-webkit-scrollbar-thumb {
  background: #798383;
}

.onclick-close {
  overflow-y: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 100;
}

.header-openPid-top {
  width: 100%;
  min-height: 8%;
  background-color: #FAF6F6;

  position: fixed;
  z-index: 10;

  display: flex;
  align-items: center
}

.openPid-main-container {
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;

}

.loading-indicator-loadingsvg10 {
  position: relative;
  display: flex;
  top: 100px;
  margin: auto;
  justify-content: center;
  align-items: center;
  transform: scale(4);
  width: 50px;
}

.menu-line {
  background-color: #38A09D;
  height: 100px;
}


.feed1-search {
  position: relative;
  top: 3px;
  height: 40px;
  width: 729px;
  padding-top: 10px;
  overflow-y: scroll;
  justify-content: center;
  border: 0.5px solid #38A09D;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1000;
  background-color: white;
}

.feed1-search:hover {
  box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.feed1-search-main {
  min-height: 55px;
  position: fixed;
  z-index: 9999;
  top: 113px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 7px;
}

.feed1-search-main-home-page {
  min-height: 55px;
  position: fixed;
  z-index: 9999;
  top: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 7px;
}