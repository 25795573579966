.block-annonimus {
    position: relative;
    width: 100%;
    height: 82px;
    align-items: center;
    text-align: center;
    top: 90px;
}

.user-anon-container-div-style {
    background-size: cover;
    background-position: center;
    position: absolute;
    border-radius: 7px;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.block-annonimus-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 117.7%;
    align-items: center;
    width: 300px;
    position: relative;
}

.block-annonimus-text-mobile {
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 117.7%;
    align-items: center;
    text-align: center;
    max-width: 550px;
}

.block-annonimus-text-link {
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 35px;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #37A09D;
    cursor: pointer;
}

.block-annonimus-text-unlink {
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 35px;
    align-items: center;
    text-align: center;
    color: #37A09D;
}


.users-followers-text:hover {
    background-color: #e4e8e8;
    border-radius: 30px;
    width: 86px;
    position: relative;
    left: 5px;
}