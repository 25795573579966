.show-commentary-txt {
    font-family: "Frank Ruhl Libre-regular";
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.13px;
    text-align: justify;
    color: #050505;
}

.show-commentary-txt-title {
    font-family: "Frank Ruhl Libre-regular";
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: right;
    color: #979797;
}

.show-commentary-txt-icon{
    text-align: left;
}

.show-commentary-txt big {
    font-family: "Frank Ruhl Libre-Medium";
}

.show-commentary-txt>b {
    font-family: "Frank Ruhl Libre-Medium";
}

@media screen and (max-width: 450px) {
    .show-commentary-txt {
        font-size: 15px;
    }
}