.loading-indicator-loadingsvg2 {
    display: flex;
    top: 400px;
    right: 50%;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: scale(4);
}

.loading-indicator-loadingsvg211 {
    position: relative;
    display: flex;
    top: 26px;
    right: 50%;
    margin: auto;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: scale(4);
    
}



.selected-Unit-Text {
    position: relative;
    top: 8px;
    right: 33px;
    min-height: 35px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    align-items: center;
    text-align: justify;
    width: 333px;
    color: #494949;
}

.lll {
    display: none;
}




.show-hidushim-send:empty {
    font-size: 21px;
    color: #B7C0C0;
}

span#cke_1_bottom {
    display: none;
}

.show-hidushim-send {
    position: relative;
    width: 88%;
    min-height: 174px;
    right: 5%;
    top: 13px;
    border: 0.5px solid #38a09d;
    border-radius: 7px;
    padding: 2px;
}

.show-hidushim-send-text {
    position: absolute;
    height: 26px;
    right: 8%;
    top: 19px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-align: right;
    color: #DBE2DE;
}

.show-hidushim-send-input:empty {
    position: relative;
    color: #67696a;
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    right: 30px;
}

.show-hidushim-send-title {
    position: relative;
    width: 357px;
    min-height: 25px;
    right: 20px;
    top: 12px;
    resize: inherit;
    border: 0.5px solid #B4CCCB;
    border-radius: 7px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 17px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
}

.show-hidushim-send-input {
    position: relative;
    width: 300px;
    min-height: 150px;
    right: 20px;
    top: 12px;
    resize: inherit;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    outline: none;
    background: yellow;
}

.show-hidushim-send-input-new {
    position: relative;
    width: 300px;
    min-height: 150px;
    right: 10px;
    resize: inherit;
    border-bottom-right-radius: 25px;
    border-bottom-left-radius: 25px;
    outline: none;
}

.show-hidushim-send:focus {
    outline: none !important;
}

.show-hidushim-send:focus {
    outline: none !important;
}

.show-hidushim-send-input-none {
    display: none;
}

.show-hidushim-send-post-rep {
    width: 81px;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 18px;
    position: relative;
    right: 73%;
    cursor: pointer;
}

.show-hidushim-send-post-rep:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}

.show-hidushim-send-post-rep-empty {
    width: 81px;
    height: 24.19px;
    background: #ffffff;

    box-sizing: border-box;
    border-radius: 8px;
    top: 18px;
    position: relative;
    right: 73%;
    cursor: pointer;
}

.show-hidushim-send-post-rep-articel-main {
    display: flex;
    width: 300px;
}

.show-hidushim-send-post-rep-articel {
    width: 110px;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 18px;
    position: relative;
    right: 10%;
    cursor: pointer;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    text-align: center;

}

.show-hidushim-send-post-rep-articel:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}

.show-hidushim-send-post-rep-articel-green {
    width: 110px;
    height: 24.19px;
    background-color: #e4e8e8;
    box-sizing: border-box;
    border-radius: 8px;
    top: 18px;
    position: relative;
    right: 10%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
}

.show-hidushim-send-post-rep-articel-green:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}

.show-hidushim-send-post-rep-cancel {
    width: 81px;
    height: 24.19px;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    top: 18px;
    position: relative;
    right: 73%;
    cursor: pointer;
}

.show-hidushim-send-post-rep-cancel:hover {
    background-color: #f1f8f6;
}

.show-hidushim-send-post-cancel {
    width: 81px;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 33px;
    position: relative;
    right: 150px;
    cursor: pointer;
}

.show-hidushim-send-post-rep-text-empty {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #9fabaa;
    position: relative;
    right: 20px;
    top: 1px;
}

.show-hidushim-send-post-rep-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    right: 20px;
    top: 1px;
}

.show-hidushim-send-post-rep-text-articel {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    position: relative;
    top: 1px;
    justify-content: center;
}

.show-hidushim-send-post-rep-text-articel-green {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #38A09D;
    position: relative;
    top: 1px;
    justify-content: center;
}

.show-hidushim-send-post-rep-text-articel-green:hover {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #dae1e1;
    position: relative;
    top: 1px;
    justify-content: center;
}

.show-hidushim-send-post-rep-text-cancel {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: red;
    position: relative;
    right: 20px;
    top: 1px;
}

.show-hidushim-main {
    position: relative;
    top: 10px;
}

.hidushim {
    display: flex;
}

.hidushim-person {
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #38a09d;
    min-height: 37px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 175px;
}

.hidushim-person-main {
    display: flex;
    width: 100%;
    cursor: pointer;
    justify-content: space-between;
}

.hidushim-tree-dont {
    position: relative;
    border-radius: 50%;
    top: 0px;
    cursor: pointer;
    height: 45px;
    width: 45px;
    justify-content: space-evenly;
    z-index: 9999999999999999999999;
    opacity: 0.5;
    box-sizing: border-box;
    right: 14px;
}

.hidushim-tree-dont-menu-triangle::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 21px solid transparent;
    border-right: 21px solid transparent;
    border-bottom: 21px solid #ffffff;
    z-index: 99999;
    right: -21px;
    top: 1px;
}

.hidushim-tree-dont-menu-triangle {
    content: '';
    position: absolute;
    left: 15px;
    top: 70px;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 22px solid #E2DBDB;
    z-index: 9999;
}

.hidushim-tree-dont-menu {
    position: absolute;
    cursor: pointer;
    background: #FFFFFF;
    border: 1px solid #E2DBDB;
    box-sizing: border-box;
    box-shadow: 4px 4px 7px rgb(0 0 0 / 11%);
    right: 238px;
    top: 71px;
    z-index: 99999;
    font-size: 0.8em;
    display: block;
    justify-content: space-evenly;
    line-height: 31px;
    font-family: Heebo;
    font-style: normal;
    border-radius: 10px;
}

.reply {
    top: 70px;
}

.reply-triangle {
    top: 61px;
}

.hidushim-tree-dont:hover {
    background-color: #f1f8f6;
}

.hidushim-tree-dont-menu-item {
    width: 150px;
    border-radius: 5px;
    position: relative;
    display: flex;
    color: #656565;
    justify-content: center;
}

.hidushim-tree-dont-menu-item:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.hidushim-date {
    position: relative;
    right: 5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    mix-blend-mode: normal;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    height: 25.23px;
    font-size: 13px;
    left: -22px;
    top: -20px;
    width: 120px;
    white-space: nowrap;
    box-shadow: 1px 1px 3px #222222;
    opacity: 0;
    border: 1px solid #979797;
    z-index: 99999;
    visibility: hidden;
    background-color: #3D3F3F;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #bfbcbc;
    transform: rotate(-0.18deg);
    line-height: 25px;
}

[data-title] {
    position: relative;
}

.hidushim-date-sub {
    position: relative;
    right: 5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    mix-blend-mode: normal;
}

.hidushim-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    align-items: center;
    text-align: right;
    color: #6d6d6d;
    text-align: justify;
    width: 340px;
    top: 6px;
    right: 33px;
    font-size: 14px;
}

.hidushim-read-more {
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
}

.hidushim-flex {
    display: flex;
    position: relative;
    right: 35px;
    top: 10px;
    width: 220px;
    height: 38px;
}

.hidushim-flex-isEditable {
    display: flex;
    position: relative;
    width: 290px;
}

.hidushim-like {
    width: 25px;
    top: 4px;
    position: relative;
    cursor: pointer;
}

.hidushim-like-small {
    width: 19px;
    top: 8px;
    position: relative;
    cursor: pointer;
}

.hidushim-like-text {
    width: 45px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
}

.selected-Unit-Text-12 {
    position: relative;
    top: 10px;
}

.hidushim-line-main {
    position: relative;
    width: 90%;
    height: 0px;
    border: 0.75px solid #e0e6e2;
    top: 7px;
}

.hidushim-comment {
    width: auto;
    right: 5px;
    top: 8px;
    position: relative;
}

.hidushim-comment-small {
    width: auto;
    right: 7px;
    top: 10px;
    position: relative;
}

.hidushim-count {
    width: 1px;
    position: relative;
    right: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
}

.hidushim-count.bbb {
    right: -8px;
}

.hidushim-like-count {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
}

.hidushim-comment-text {
    width: 68px;
    position: relative;
    right: 15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
}

.hidushim-all-comment {
    width: 100px;
    position: relative;
    right: 250px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
}

p {
    white-space: pre-wrap;
    display: contents;
}

p.bookChosen-page-item-text {
    display: block;
}

pre {
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    white-space: pre-wrap;
}

textarea:focus {
    outline: none !important;
}

textarea {
    line-height: 50px;
}

.hidushim-reply {
    position: relative;
    width: 338px;
    min-height: 24px;
    right: 32px;
    top: 14px;
    border: .5px solid #38a09d;
    border-radius: 30px;
    resize: inherit;
    color: .5px solid #dbe2de;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 39px;
    padding-top: 4px;
    padding-right: 10px;
    outline: none;
}

.hidushim-reply:empty {
    color: #67696a;
}

.show-hidushim-send-post {
    position: relative;
    height: 70px;
    display: flex;
}

.cke_top, .cke_reset_all {
    border-radius: 7px;
    background-color: #F9FAFA !important;
    border: #FFFFFF !important;
}

.show-hidushim {
    position: relative;
    top: -5px;
}

.hight {
    min-height: 70px;
}

.hidushim-reply-send {
    position: relative;
    font-family: Heebo;
    width: 93.25px;
    height: 37px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    align-items: center;
    text-align: center;
    cursor: pointer;
    top: 15px;
    right: 192px;
}

.hidushim-reply-send-cancel {
    position: relative;
    font-family: Heebo;
    width: 93.25px;
    height: 37px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    color: #FFFFFF;
    align-items: center;
    text-align: center;
    cursor: pointer;
    top: 15px;
    right: 180px;
}

.hidushim-reply-send:hover {
    background-color: #e4e4e4;
    color: #38A09D;
}

.hidushim-reply-send-text {
    padding-top: 5px;
}

.cke_bottom {
    display: none;
}

.cke_resizer_rtl, .cke_path, .cke_voice_label {
    display: none;
}

.show-hidushim-button:hover {
    background-color: #eefeff;
    color: #37A09D;
}

.show-hidushim-button {
    position: relative;
    width: 40px;
    background-color: #37A09D;
    text-align: center;
    right: 10px;
    height: 25px;
    cursor: pointer;
}

blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 50px;
    padding: 0em 2em 0 1em;
    border-right: solid 2px #38A09D;
    background: #f8f9f9;
}

.recent-feed-text blockquote {
    width: 529px;
}

.cke[id^='cke_editor'] {
    display: block;
    border: 0px solid #38A09D;
    padding: 0;
    border-radius: 7px;
    z-index: 100;
}

.show-hidushim-select-editor {
    right: 275px;
    width: 100px !important;
    position: absolute;
    z-index: 9;
    top: 54px;
}

.select-editor__control {
    position: relative !important;
    border-radius: 6px !important;
    width: 93px !important;
    background: #f8f8f8 !important;
}

.select-editor__option:hover {
    background: #f8f8f8 !important;
}

.select-editor__option--is-selected {
    background: #38A09D !important;
}

.select-editor__control--is-focused {
    position: relative !important;
    border-radius: 7px !important;
    border-color: #38A09D !important;
    box-shadow: 0 0 0 1px #38A09D !important;
    width: 93px !important;
    height: 5px !important;
    border: 0px !important;
    background: #f8f8f8 !important;
}

.show-hidushim-CloseIcon {
    position: relative;
    margin-top: 8px;
    margin-right: 360px;
    color: #4D4C4C;
    cursor: pointer;
    z-index: 1111;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
}

.show-hidushim-CloseIcon:hover {
    border-radius: 100%;
    background-color: #f0f0f0;
}

.show-hidushim-template {
    width: 140px;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 15px;
    position: absolute;
    right: 10%;
    cursor: pointer;
    justify-content: center;
    margin-left: 10px;
    align-items: center;
    text-align: center;
}

.show-hidushim-template-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    position: relative;
    top: 1px;
    justify-content: center;
}

.show-hidushim-template:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}