.templates-main {
    position: fixed;
    display: flex;
    left: 5%;
    top: 30px;
    width: 90%;
    height: 75%;
    z-index: 100;
    background-color: #e7e7e7eb;
}


.templates-CKEditor {
    position: relative;
    border: 0.5px solid #38a09d;
    border-radius: 7px;
    padding: 2px;
    z-index: 101;
    height: 99%;
    width: 100%;
    min-width: 20%;
}

.templates-explanations-main {
    position: relative;
    background-color: #FFF;
    height: 100%;
    border-radius: 10px;
    border: 0.5px solid #38a09d;
    overflow: scroll;
}

.templates-explanations-text {
    position: relative;
    top: 5%;
    right: 1.5%;
    width: 97%;
}

.templates-line {
    background-color: #000000;
    position: relative;
    width: 1px;
    height: 100%;
    z-index: 222;
    cursor: e-resize;
}

.templates-button {

    height: 24.19px;
    background: #38A09D;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
}

.templates-button-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #FFFFFF;
    position: relative;
    top: 1px;
    justify-content: center;
}

.templates-send-post-rep {
    width: 20%;
    right: 79%;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 0.9%;
    position: relative;
    cursor: pointer;
}

.templates-send-post-rep:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}

.templates-send-post-rep-empty {
    width: 20%;
    right: 79%;
    height: 24.19px;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    top: 0.9%;
}

.templates-send-post-rep-text-empty {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    color: #9fabaa;
    position: relative;
    top: 1px;
}

.templates-send-post-rep-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    position: relative;
    top: 1px;
}