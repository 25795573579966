.choose-mefarshim {
    width: 400px;
    overflow: hidden;
    height: 100%;
}

.choose-mefarshim-form {
    display: flex;
    flex-direction: column;
    height: 91%;
    width: auto;
}

.commentary-he-name-container {
    cursor: auto;
    padding-right: 23px;
    border-radius: 8px;
    width: 300px;
}

.commentary-he-name-container:hover {
    background-color: #f1f8f6;
}

.commentary-he-name {
    font-family: Heebo, serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.42;
    letter-spacing: 0.13px;
    text-align: right;
    color: #37A09D;
}

.commentary-he-name-none-select {
    font-family: Heebo, serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.42;
    letter-spacing: 0.13px;
    text-align: right;
    color: #050505;
    opacity: 0.5;
}

.commentary-he-name-input {
    width: 12px;
    height: 12px;
    border-radius: 1px;
    border: solid 1.5px #3c3c3c;
    margin-left: 10px;
}

.submit-container {
    width: 400px;
    position: relative;
    bottom: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    cursor: auto;
    background-color: white;
}

.submit-btn-none-selected {
    box-sizing: border-box;
    width: 346px;
    height: 46px;
    opacity: 0.5;
    border-radius: 4px;
    border: solid 1px #b9b9b9;
    margin: 0 auto;
    font-family: Heebo, serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 0.11px;
    text-align: center;
}

.submit-btn-selected {
    box-sizing: border-box;
    width: 346px;
    height: 46px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 auto;
    border: solid 1px #65d3cf;
    background-color: #38A09D;
    font-family: Heebo, serif;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 0.11px;
    text-align: center;
    color: #ffffff;
}

.submit-btn-selected:hover {
    background-color: #65d3cf;
}

.submit-txt-none-selected {
    font-family: Heebo, serif;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.88;
    letter-spacing: 0.11px;
    text-align: center;
    color: #050505;
    cursor: auto;
}

.selection-container {
    display: flex;
    flex-direction: column;
    height: 90%;
    overflow-y: scroll;
    width: 400px;
    position: relative;
    top: 0;
}

.selection-container::-webkit-scrollbar {
    width: 9px;
    height: 1px;
    background-color: #e5e7e7;
}

.selection-container::-webkit-scrollbar-thumb {
    background: #798383;
}

.sub-sfarim {
    width: 400px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    font-family: Heebo, serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: 0.14px;
    text-align: right;
    color: #050505;
}

.sub-sfarim-about {
    justify-content: center;
    width: 400px;
    display: flex;
    top: 10px;
    position: relative;
}

.choose-mefarshim-about-text {
    position: relative;
    width: 331px;
    text-align: justify;
    font-size: 16px;
    right: 20px;
}

.choose-mefarshim-about-icon {
    position: relative;
    width: 331px;
    text-align: left;
    cursor: pointer;
    right: 21px;
}

.sub-sfarim-mobile {
    width: 90%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    font-family: Heebo, serif;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.19;
    letter-spacing: 0.14px;
    text-align: right;
    color: #050505;
}

.sub-sfarim-title-container-sfarim-nosafim {
    width: 155px;
}

.sub-sfarim-open-line {
    width: 73%;
    height: fit-content;
    position: relative;
    top: 2px;
    margin-left: 16px;
    margin-right: 0%;
    border: solid 1px #979797;
}

.sub-sfarim-title {
    font-family: Heebo, serif;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.11px;
    text-align: right;
    color: #979797;
    padding-right: 20px;
}

.no-relevant-kishurim {
    padding-right: 25px;
    font-family: Heebo, serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.42;
    letter-spacing: 0.13px;
    text-align: right;
    color: #050505;
    opacity: 0.8;
}

@media screen and (max-width: 450px) {
    .choose-mefarshim {
        height: 120%;
        width: 100%;
    }

    .submit-container {
        width: 100%;
        position: fixed;
        bottom: 74px;
    }

    .submit-btn-none-selected {
        width: 80%;
        height: 30px;
        font-size: 12px;
        line-height: 0.88;
        letter-spacing: 0.11px;
    }

    .submit-btn-selected {
        width: 80%;
        height: 30px;
        font-size: 12px;
        line-height: 0.88;
        letter-spacing: 0.11px;
    }
}

.choose-mefarshim-CloseIcon {
    position: absolute;
    margin-top: 8px;
    margin-right: 8px;
    color: #4D4C4C;
    cursor: pointer;
    z-index: 1111;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
}

.choose-mefarshim-CloseIcon:hover {
    border-radius: 100%;
    background-color: #f0f0f0;
}