.book-page-header-up {
    position: fixed;
    width: 100%;
    height: 66px;
    left: 0px;
    top: -10px;
    background: #FFFFFF;
    box-shadow: 0px 0.5px 4px rgb(0 0 0 / 25%);
    z-index: 100;
}

.book-page-header-down {
    display: flex;
    position: fixed;
    width: 100%;
    height: 57px;
    left: 0px;
    background: #FFFFFF;
    box-shadow: 0px 0.5px 4px rgb(0 0 0 / 25%);
    justify-content: center;
    top: 56px;
    z-index: 200;
}

.book-page-header-down-sfarim {
    position: relative;
    width: 163px;
    height: 51px;
    top: 3px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    cursor: pointer;
    justify-content: space-evenly;
}

.book-page-header-down-mechadshim {
    position: relative;
    width: 195px;
    height: 51px;
    top: 3px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    cursor: pointer;
    justify-content: space-evenly;
}

.book-page-header-down-sfarim:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.book-page-header-down-mechadshim:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.book-page-header-down-last:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.book-page-header-down-last {
    position: relative;
    height: 51px;
    top: 3px;
    width: 190px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    cursor: pointer;
    justify-content: space-evenly;
}

.book-page-header-down-line {
    position: relative;
    top: 15px;
    width: 27px;
    display: flex;
    justify-content: center;
}

.book-page-header-down-sfarim-icon {
    position: relative;
    top: 5px;
}

.logo-svg-main {
    position: relative;
    display: flex;
    right: 23px;
    top: 15px;
    width: 47px;
    height: 47px;
    justify-content: center;
    align-items: center;

}

.logo-svg {
    position: relative;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 37px;
    border-radius: 50px;
    top: -1px;
    left: 1px;
}



.logo-svg-main:hover {
    background-color: #e4e8e8;
    border-radius: 50px;
}

.new-menu-header-right {
    display: flex;
    position: absolute;
    width: 75%;
    height: 25px;
    right: 10%;
    top: 26px;
}

.new-menu-header-right-connect {
    right: 10px;
    position: relative;
    width: 80px;
    height: 38px;
    top: -5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-connect:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-connect-yoma {
    right: 10px;
    position: relative;
    width: 260px;
    height: 23px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
}

.new-menu-header-right-pesah {
    right: 10px;
    position: relative;
    width: 200px;
    height: 23px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-about {
    right: 10px;
    position: relative;
    width: 150px;
    height: 38px;
    display: flex;
    justify-content: center;
    top: -5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-about:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-work {
    right: 10px;
    position: relative;
    width: 120px;
    height: 38px;
    display: flex;
    justify-content: center;
    top: -5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-work:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-contact {
    right: 10px;
    position: relative;
    width: 95px;
    height: 38px;
    display: flex;
    justify-content: center;
    top: -5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-contact:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-contact-shas {
    right: 10px;
    position: relative;
    width: 120px;
    height: 38px;
    display: flex;
    justify-content: center;
    top: -5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.new-menu-header-right-contact-shas:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-line {
    position: relative;
    width: 20px;
    transform: rotate(180deg);
}

.new-menu-header-right-profile {
    position: fixed;
    display: flex;
    width: 190px;
    justify-content: space-between;
    left: 20px;
    top: 8px;
    z-index: 201;
    cursor: pointer;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    align-items: center;
    text-align: left;
    color: #656565;
}

.new-menu-header-right-profile-search-icon:hover {
    background-color: #f1f8f6;
    border-radius: 50%;
}

.new-menu-header-right-notification-in {
    position: absolute;
    width: 38.6px;
    height: 38.6px;
    left: 90px;
    top: 1px;
    cursor: pointer;
}

.new-menu-header-right-bell-in {
    position: absolute;
    left: 78.9%;
    top: 26.7%;
    bottom: 95.55%;
    background: #04fff263;
    cursor: pointer;
}

.new-menu-header-right-bell:hover {

    -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.25);
    border-radius: 50%;

}

.new-menu-header-right-bellsub-in {
    position: absolute;
    left: 84.4%;
    top: 35.52%;
    bottom: 95.26%;
    background: #37A09D;
}

.new-menu-header-right-profile-profile-in {
    position: relative;
    left: 5.76%;
    top: 25.65%;
    bottom: 95.38%;
    background: #FFFFFF;
}

.new-menu-header-right-profile-men {
    position: absolute;
    left: 7px;
    top: 7.2px;
    cursor: pointer;
}

.new-menu-header-right-profile-conncet {
    position: absolute;
    width: 64px;
    height: 23px;
    top: 8px;
    left: 42px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    cursor: pointer;
    ;
}

.clikable {
    cursor: pointer;
}

.new-menu-header-right-bell-in-not {
    position: absolute;
    top: -7px;
    justify-content: left;
}

.new-menu-header-right-bell-in-not-text {
    position: absolute;
    top: 3px;
    width: 100%;
    text-align: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    color: #ffffff;
}

.green {
    color: #38A09D;
    border-bottom: 5px solid #38A09D;
    background-color: #e4e8e8;
    border-radius: 5px;
}

.new-menu-header-right-profile-connct {
    position: absolute;
    top: 0px;
    left: -1px;
    z-index: 100;
}

.new-menu-header-right-profile-connct:hover {
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
    border-radius: 30px;
    height: 41px;
}

.new-menu-header-right-profile-connct-text {
    position: absolute;
    top: 4px;
    left: 47px;
}

.new-menu-email {
    position: relative;
    right: 49px;
    top: -35px;
    cursor: pointer;
}

.new-menu-header-right-profile-search {
    position: relative;
    width: 257px;
    height: 39.5px;
    border: 1px solid #67c4c1;
    box-sizing: border-box;
    border-radius: 30px;
    top: -4px;
    z-index: 100;
}

.new-menu-header-right-profile-search:hover {
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.new-menu-header-right-profile-search-input {
    height: 29px;
    border: white !important;
    right: 20px;
    position: absolute;
    top: 4px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    width: 179px;
    box-shadow: none !important;
}



/*//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/

/*עיצוב חדש*/

.book-page-header {
    position: relative;
    width: 100%;
    height: 74px;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
}

.header-titles-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    top: 0;
    height: 74px;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.header-nav-title-logo {
    padding-top: 17px;
    padding-left: 30px;
    padding-right: 10px;
    width: 80px;
    height: 40px;
}

.header-nav-bookcase-title {
    font-family: Heebo, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #151515;
    cursor: pointer;
    height: fit-content;
}

.header-nav-chidushei-tota-title {
    font-family: Heebo, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    color: #151515;
    cursor: pointer;
    height: fit-content;
}

.header-nav-chidushei-tota-title-none {
    display: none;
}

.header-nav-bookcase {
    position: relative;
    display: flex;
    padding-top: 21px;
    z-index: 99999;
    cursor: pointer;
    user-select: none;
}

.header-nav-chidushei-tota {
    display: flex;
    padding-top: 21px;
    z-index: 99999;
    cursor: pointer;
    user-select: none;
}

.header-nav-chidushei-tota:hover {
    background-color: #f9f9f9;
}

.header-nav-bookcase-open {
    border-bottom: solid 5px #38A09D;
}

.header-nav-chidushei-tota-open {
    border-bottom: solid 5px #38A09D;
}

.header-nav-bookcase-svg {
    height: fit-content;
    margin-top: 5px;
    margin-left: 10px;
}

.header-nav-chidushei-tota-svg {
    height: fit-content;
    margin-top: -3px;
    margin-left: 10px;
}

.header-nav-chidushei-tota-svg-1 {
    height: fit-content;
    margin-top: 4px;
    margin-left: 10px;
}

.header-nav-gmara-wrapper {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.header-nav-ein-mispot-wrapper {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    justify-content: space-around;
    left: 430px;
    position: relative;
    top: 5px;
    width: 85px;
    text-align: justify;
    text-align-last: center;
}

.header-nav-ein-mispot-wrapper-open-left {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    justify-content: space-around;
    left: 275px;
    position: absolute;
    top: 34px;
    width: 85px;
    text-align: justify;
    text-align-last: center;
}

.header-nav-ein-mispot-wrapper-open-right {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    justify-content: space-around;
    left: 100px;
    position: absolute;
    top: 34px;
    width: 85px;
    text-align: justify;
    text-align-last: center;
}

.header-nav-ein-mispot-wrapper-left {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 12.4px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    justify-content: space-around;
    right: 430px;
    position: relative;
    top: 5px;
    width: 85px;
    text-align: justify;
    text-align-last: center;
}

.header-nav-gmara-wrapper-yer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    max-width: 60%;
    margin: auto;
    cursor: pointer;
}

.header-nav-gmara-wrapper-Liturgy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 100%;
    max-width: 60%;
    margin: auto;
    cursor: pointer;
    top: 10px;
}

.header-nav-gmara-wrapper-Liturgy-m {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 100%;
    max-width: 85%;
    margin: auto;
    cursor: pointer;
    top: 25px;
}

.header-nav-gmara-wrapper-tanach {
    display: flex;
    flex-direction: row;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 44%;
    top: 11px;
    right: 22%;
    justify-content: space-between;
    cursor: pointer;
}

.header-nav-gmara-wrapper-tanach:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-gmara-wrapper:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-gmara-wrapper-yer:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-gmara-wrapper-Liturgy:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-gmara-wrapper-shulchan-srukh:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-gmara-wrapper-shulchan-srukh-commentary:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.Commentary-header {
    width: 55%;
}

.header-nav-gmara-wrapper-shulchan-srukh {
    flex-direction: row;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    text-align: center;
    cursor: pointer;
    justify-content: space-around;
    width: 70%;
    right: 15%;
}

.header-nav-gmara-wrapper-shulchan-srukh-commentary {
    flex-direction: row;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    text-align: center;
    justify-content: space-around;
    cursor: pointer;
    width: 70%;
    right: 15%;
}

.header-nav-gmara-wrapper-shulchan-srukh-open {
    flex-direction: row;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 850px;
    right: 15%;
    text-align: center;
    cursor: pointer;
}

.header-nav-gmara-wrapper-shulchan-srukh-open {
    width: 90%;
    right: 0px;
}

.header-nav-gmara-wrapper-none {
    display: none;
}

.page-titles-container-no-page-number-tanach {
    display: flex;
    flex-direction: row;
    margin-right: 30px;
    margin-top: 45px;
}


.container-tora {
    display: flex;
    flex-direction: row;
    width: 350px;
    justify-content: space-between;
}

.header-nav-subpage-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 14%;
    padding-top: 10px;
}

.header-nav-subpage-container-tanach {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 33%;
}

.header-nav-subpage-container-shulchan-srukh {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-nav-subpage-container-shulchan-srukh-commentary {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.header-nav-subpage-container-shulchan-srukh-title {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 33px;

}

.header-nav-subpage-container-shulchan-srukh-title-m {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 25px;
}

.header-nav-subpage-container-shulchan-srukh-title-2 {
    position: relative;
    width: 70%;
    right: 15%;
}

.rrrr {
    left: 20px;
    position: relative;
}

.book-svg {
    cursor: pointer;
}

.header-nav-subpage-page-svg {
    transform: rotate(270deg);
    position: relative;
    height: 35px;
    width: 35px;
    top: -36px;
}

.header-nav-subpage-page-svg:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.header-nav-subpage-page {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: normal;
    color: #151515;
    position: absolute;
    text-align: left;
    width: 830px;
    z-index: 1;
    top: 45px;
}

.header-nav-subpage-page-ein-mispot {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 14px;
    justify-content: center;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: absolute;
    width: 50px;
    text-align: center;
}

.header-nav-subpage-page-ein-mispot-item {
    font-family: PFT_Rashi, sans-serif;
    justify-content: center;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 70px;
    text-align: center;
    top: 10px;
    cursor: pointer;

}

.header-nav-subpage-page-ein-mispot-item-index {
    font-family: PFT_vilna Bold;
    top: 10px;
    position: relative;
}

.header-nav-subpage-page-ein-mispot-item-title {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-size: 14px;
    justify-content: center;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
    position: relative;
    width: 65px;
    text-align: center;
    top: 7px;
    cursor: pointer;
    right: 9px
}

.header-nav-subpage-page-shulchan-srukh {
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #151515;
}

.header-nav-arrow-button-right {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 4px;
    padding-top: 3px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}

.header-nav-arrow-button-right-shulchan-srukh {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 4px;
    padding-top: -1px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}

.header-nav-arrow-button-right:disabled {
    opacity: 0.4;
    cursor: default;
}

.header-nav-arrow-button-left {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 8px;
    padding-top: 3px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}

.header-nav-arrow-button-left-shulchan-srukh {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 8px;
    padding-top: 0px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}

.header-nav-arrow-button-left:disabled {
    opacity: 0.4;
    cursor: default;
}

.wit {
    width: 40px;
}

.header-nav-profile-svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 43px;
    cursor: pointer;
}

.header-nav-profile-svg-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    left: 3%;
    cursor: pointer;
}

.profile-svg:hover {
    opacity: 0.8;
}

.title-name-book-page-tanach {
    width: fit-content;
    padding-top: 22px;
    width: 180px;
}

.title-name-book-page-yer {
    width: 50%;
    position: relative;
    font-size: 37px;
    line-height: 42px;
    text-align: center;
}

.title-name-book-page-yer-m {
    position: relative;
    font-size: 22px;
    top: 20px;
}

.title-name-book-page-Talmud {
    width: 100%;
    position: absolute;
    font-size: 35px;
    line-height: 42px;
}

.title-name-book-page-Liturgy {
    width: 100%;
    text-align: center;
    font-size: 35px;
    position: relative;
}

.header-nav-result-list {
    position: fixed;
    display: grid;
    border-radius: 8px;
    background-color: #ffffff;
    top: 47px;
    margin-right: 7px;
    width: 16%;
    text-align: right;
}

.header-nav-result-list-item {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
}

.header-nav-result-list-item:hover {
    background-color: #e4e8e8;
    border-radius: 8px;
    position: relative;
}

.header-nav-result-list-item-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
}

.title-name-book-page-Liturgy-m {
    width: 100%;
    text-align: center;
    font-size: 26.7px;
    position: relative;
}

.title-name-book-page-Talmud-1 {
    width: 100%;
    position: absolute;
    font-size: 35px;
    line-height: 42px;
    text-align: left;
}

.title-name-book-page-Talmud-2 {
    width: 100%;
    position: relative;
    font-size: 37px;
    line-height: 42px;
    text-align: center;
}

.title-name-book-page-teanach {
    width: 50px;
}

.title-name-book-page-1-teanach {
    width: 150px;
    padding-top: 22px;
}

.titles-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 323px;
    left: 2px;
}

.titles-container1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 1050px;
}

.titles-container-open-pid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    right: 2%;
    width: 50%;
}

.titles-container-tora {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.titles-container-tora1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    right: 30%;
}

.arrow-disabled {
    background-color: transparent;
}

.beta-header {
    width: 100%;
    height: 24px;
    border-top: solid 1px #e1e1e1;
    background-color: #f4f4f4;
    position: relative;
}

.beta-header-txt {
    width: 100%;
    height: 100%;
    font-family: Heebo;
    font-size: 13.7px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #343434;
    text-align: center;
    padding-top: 2px;
    margin: 0 auto;
}

.beta-header-span {
    font-family: Heebo;
    font-size: 13.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #38A09D;
    cursor: pointer;
    text-decoration: none;
    margin-right: 10px;
}

.beta-header-span:hover {
    text-decoration: underline;
}

.header-nav-subpage-arrow :hover {
    background-color: #c7c6c6;
}

.header-nav-bookcase:hover {
    background-color: #f9f9f9;
}

.header-nav-bookcase-open:hover {
    background-color: #f9f9f9;
}

.back {
    padding-top: 25px;
    padding-right: 25px;
    position: absolute;
}

.page-titles-container-no-page-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 1210px) {
    .header-nav-profile-svg {
        padding-left: 15px;
    }

    .panel-left-side-container {
        z-index: 0;
    }

    .panel-right-side-container {
        z-index: 1;
        right: 0px;
    }

    .not-expanded-container {
        z-index: 1;
    }
}

@media screen and (max-width: 1150px) {}

@media screen and (max-width: 1006px) {
    .header-nav-chidushei-tota-title {
        display: none;
    }

    .header-nav-bookcase-title {
        display: none;
    }

    .titles-container {
        width: 10px;
    }

    .titles-container1 {
        width: 43%;
        left: 10%;
    }
}



@media screen and (max-width: 850px) {
    .header-nav-chidushei-tota {
        padding-top: 0px;
    }

    .title-name-book-page-tanach {
        width: 100px;
    }

    .title-name-book-page-teanach {
        width: 50px;
    }

    .header-nav-subpage-container-tanach {
        width: 25%;
        z-index: 10;
    }

    .page-titles-container-no-page-number-tanach {
        margin-right: -50px;
        margin-top: 59px;
    }

    .header-nav-subpage-page {
        width: 88%;
    }
}

@media screen and (max-width: 450px) {
    .header-titles-wrapper {
        height: 44px;
    }

    .book-page-header {
        height: 70px;
    }

    .header-nav-title-logo {
        padding-top: 13px;
        padding-left: 30px;
        padding-right: 10px;
        width: 56px;
        height: 23px;
    }

    .header-nav-chidushei-tota-svg-1 {
        margin-top: 10px;
        margin-left: 10px;
    }

    .header-nav-gmara-wrapper-tanach {
        width: 70%;
        height: 60px;
        top: 35px;
        justify-content: space-between;
    }


    .title-name-book-page {
        font-size: 15px;
    }

    .header-nav-subpage-page {
        font-size: 20px;
        width: 88%;
        font-size: 13px;
        top: 35px;
    }

    .page-titles-container-no-page-number-tanach {
        margin-right: -124px;
        margin-top: 59px;
        font-size: 21px;
    }

    .header-nav-subpage-page-shulchan-srukh {
        position: relative;
        top: 25px;
        font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #151515;
    }

    .title-name-book-page-shulchan-srukh {
        width: 100%;
        position: relative;
        text-align: center;
    }

    .header-nav-gmara-wrapper-shulchan-srukh {
        font-size: 30px;
        width: 75%;
        top: 60px;
        height: 80px;
        right: 5%;
    }

    .aaaaaa {
        text-align: center;
        line-height: 31px;
        top: 19px;
        position: relative;
        height: 85px;
    }
}

@media screen and (max-width: 350px) {
    .title-name-book-page-1-teanach {
        width: 59px;
        right: 100px;
    }

    .title-name-book-page, .title-name-book-page-teanach {
        width: 46px;
    }

    .page-titles-container-no-page-number-tanach {
        margin-right: -91px;
    }
}