.feed-all-comment {
    width: 100px;
    position: relative;
    right: 561px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
    height: 40px;
}

.recent-feed-like-main {
    display: flex;
    height: 30px;
    position: relative;
    right: 540px;
    width: 100px;
}

.recent-feed-like-main-mobile {
    right: 14px;
    position: relative;
    top: 15px;
}

.recent-feed-like-main-2 {
    display: flex;
    height: 30px;
    position: relative;
    right: -40px;
    top: 10px;
}

.recent-feed-like-main-2-mobile {
    right: 0px;
}

/*-----------------------------------------------------------*/

.recent-feed-person-mobile {
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    width: 100%;
}

.recent-feed-person-sub-mobile {
    width: 100%;
}

.recent-feed-line-mobile {
    width: 100%;
    position: relative;
    height: 0px;
    right: 0px;
    border: 0.75px solid #DBE2DE;
}

.recent-feed-line-mobile-2 {
    width: 100%;
    position: relative;
    height: 0px;
    right: 0px;
    border: 0.75px solid #DBE2DE;
    top: 13px;
}

.feed-all-comment-mobile {
    width: 50%;
    right: 20px;
}

.recent-feed-text-sub-2 {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    text-align: justify;
    color: #494949;
    width: 521px;
    min-height: 25px;
    top: 10px;
}

.recent-feed-text-sub-2-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15.5px;
    letter-spacing: 0.5px;
    line-height: 115.88%;

    width: 100%;
    display: flex;
    align-items: center;
    text-align: right;
    top: 2px;
}

