.ggg {
    white-space: pre-line;
}

.hidushim-send-text {
    position: relative;
    height: 0px;
    right: 10.5%;
    width: 20px;
    top: 29.5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: right;
    color: #DBE2DE;
}

.cke_chrome{
    display: block;
    border: none;
    padding: 0;
}