.form-page-mobile {
    position: relative;
    width: 298px;
    height: 308px;
    top: 20%;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgb(0 0 0 / 10%);
    border-radius: 15px;
    justify-content: center;
    display: grid;
}

.form-page-mobile-user-name {
    position: relative;
    width: 232.33px;
    height: 27.92px;
    top: 35px;
    border: 0.75px solid #38A09D;
    border-radius: 8px;
    justify-content: center;
}

.form-page-mobile-password {
    position: relative;
    width: 232.33px;
    height: 27.92px;
    top: 30px;
    border: 0.75px solid #38A09D;
    border-radius: 8px;
    justify-content: center;
}

.form-page-mobile-user-name-input {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #656565;
    width: 198.46px;
    height: 17.36px;
    justify-content: center;
    right: 10px;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    top: 3px;
}

.form-page-mobile-password-input {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #656565;
    width: 198.46px;
    height: 17.36px;
    justify-content: center;
    right: 10px;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    top: 3px;
}

.form-page-mobile-forget-pass {
    position: relative;
    top: 20px;
    right: 40px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
    height: 40px;
}

.form-page-mobile-connect {
    position: relative;
    width: 233.11px;
    height: 27.92px;
    top: 25px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.form-page-mobile-sign-up {
    position: relative;
    width: 233.11px;
    height: 27.92px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.form-page-mobile-connect-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    color: #FFFFFF;
}

.form-page-mobile-sign-up-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    color: #FFFFFF;
}

.form-page-mobile-trial {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    position: relative;
    right: 65px;
}

.form-page-mobile-exist-account {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
    position: relative;
    right: 40px;
}