.item-name {
    cursor: pointer;
}

.x-svg-container {
    display: inline;
    padding-left: 6px;
    padding-right: 5px;
    margin-right: 5px;
    cursor: pointer;
    height: 100%;
}