.message-menu {
    position: absolute;
    width: 315px;
    height: 340px;
    left: 28px;
    top: 55px;
    background: #FFFFFF;
    border: 1px solid #E2DBDB;
    box-sizing: border-box;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
    z-index: 999;
    overflow-y: scroll;
}
.message-menu::-webkit-scrollbar {
    width: 7px;
    height: 1px;
    background-color: #e5e7e7;
    
  }
  
  .message-menu::-webkit-scrollbar-thumb {
    background: #798383;
  
  }

.message-menu-none {
    display: none;
}

.message-menu-title {
    width: 100%;
    height: 36px;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    top: 25px;
    color: #000000;
    mix-blend-mode: normal;
    font-weight: bold;
}
.hight-line{
    height: 20px;
}
.message-menu-line-1 {
    position: relative;
    width: 225px;
    height: 0px;
    right: 46px;
    top: 28px;
    border: 1px solid #38A09D;
}

.message-menu-item-text {
    position: relative;
    height: auto;
    right: 46px;
    top: 40px;
    width: 221px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-align: justify;
}

.text-align {
    text-align: left;
    height: 26px;
    top: 5px;
}

.cursur {
   line-height: 20px;
    top: 7px;
    position: relative;
}

.jjjjjj {
    overflow: hidden;
    color: black;
    top: 4px;
    position: relative;
    line-height: 20px;
}

.message-menu-item-text-mail {
    position: relative;
    height: 26px;
    top: 40px;
    width: 70%;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #38A09D;
}

.edededed {
    float: left;
    color: #38A09D;
}

.sdsd {
    position: relative;
    text-align: justify;
    width: 222px;
    right: 45px;
}

.message-menu-item-text b {
    color: black;
}

.message-menu-item-text-mail b {
    color: black;
}

.message-menu-line {
    position: relative;
    width: 225px;
    height: 0px;
    right: 46px;
    top: 51px;
    border: 1px solid #B7C0C0;
}

.message-menu-item-text-read {
    background-color: #eaecf0;
    position: relative;
    height: auto;
    right: 15%;
    top: 40px;
    width: 260px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #38A09D;
    text-align: justify;
}

.message-menu-more {
    width: 124.73px;
    height: 0px;
    right: 150px;
    bottom: 31px;
    position: absolute;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #38A09D;
    mix-blend-mode: normal;
}

.message-menu-title-email {
    width: 100%;
    height: 36px;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    top: 25px;
    color: #000000;
    mix-blend-mode: normal;
    font-weight: bold;
}