.color {
    color: #eae7e7;
}

.openNav-book-item-yoma {
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 48px;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    font-family: Heebo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
    margin: 70px 0px;
    cursor: pointer;
    box-sizing: border-box;
}
.openNav-book-item-yoma-hid {
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 48px;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    font-family: Heebo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #b1b1b1;
    margin: 70px 0px;
    cursor: pointer;
    box-sizing: border-box;
}

.openNav-allbooks-Chosen-masechet-icon {
    position: relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    top: 10px;
}

.openNav-allbooks-Chosen-masechet-icon-text {
    position: absolute;
    text-align: center;
    top: 22px;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-style: normal;
    font-weight: 900;
    color: #656565;
}

.openNav-allbooks-Chosen-masechet-icon-text-big {
    font-size: 55px;
    line-height: 70px;
    top: 10px;
    position: relative;
}

.openNav-allbooks-Chosen-masechet-icon-text-big-ironSwords {
    font-size: 50px;
    line-height: 70px;
    top: 15px;
    position: relative;
    text-align: justify;
}

.openNav-allbooks-Chosen-masechet-icon-text-big-mobile {
    font-size: 20px;
}

.openNav-allbooks-Chosen-masechet-icon-text-big-2 {
    font-size: 30px;
    position: relative;
    top: 10px;
}

.openNav-allbooks-Chosen-masechet-icon-text-big-2-mobile {
    font-size: 12px;
}

.openNav-allbooks-Chosen-masechet-icon-text-small {
    font-size: 20px;
    top: 10px;
    position: relative;
}

.openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords {
    font-size: 33px;
    top: 7.5px;
    position: relative;
    text-align: center;
    font-weight: 500;
}

.openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords-2 {
    font-size: 16.5px;
    top: 10px;
    position: relative;
    text-align: center;
}
.openNav-allbooks-Chosen-masechet-total {
    font-size: 20px;
    top:37px;
    position: relative;
    text-align: center;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
}
.openNav-allbooks-Chosen-masechet-total-m {
    font-size: 17px;
    top: 37px;
    position: relative;
    text-align: center;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
}

.openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords-3 {
    font-size: 10px;
    top: 10px;
    position: relative;
    text-align: center;
}

.openNav-allbooks-Chosen-masechet-icon-text-small-ironSwords-4 {
    font-size: 20px;
    top: 0px;
    position: relative;
    text-align: justify;
    word-spacing: 16px;
}

.openNav-allbooks-Chosen-masechet-icon-text-mobile {
    position: absolute;
    width: 60%;
    text-align: center;
    top: 15px;
    font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    color: #656565;
}

.openNav-book-item-yoma:hover {
    border: solid 2px #0fbab5;
    border-radius: 4px;
}

.openNav-book-item-yoma-mobile {
    margin: 40px 0px;
    width: 125px;
}

.bookChosen-page-item-text-yoma {
    top: 7px;
    position: relative;
}

.openNav-book-item-yoma-1 {
    direction: ltr;
    justify-content: center;
    align-items: center;
    width: 155px;
    height: 48px;
    border-radius: 4px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
    border: solid 1px #dfdfdf;
    background-color: #ffffff;
    font-family: Heebo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
    cursor: pointer;
    box-sizing: border-box;
}

.openNav-book-item-yoma-1:hover {
    border: solid 2px #16c4bf;
    border-radius: 4px;
}

.bookChosen-page-item-text-3 {
    direction: ltr;
    height: 34px;
    margin-bottom: 0px;
    font-family: Heebo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
    margin: 0;
}

.bookChosen-page-item-text-3-yoma {
    direction: ltr;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
    margin: 0;
    position: relative;
    top: 22px;
    font-family: Heebo;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    color: #e4e4e4;
    cursor: pointer;
    border-radius: 23.1px;
    background: #38A09D;
    justify-content: center;
    align-items: center;
    padding: 2px 15px;
}

.bookChosen-page-item-text-3-yoma-black {
    direction: ltr;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
    margin: 0;
    position: relative;
    top: 25px;
    font-family: Heebo;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
    color: #5e5e5e;
    cursor: pointer;
    border-radius: 23.1px;
    background: #e4e4e4;
    justify-content: center;
    align-items: center;
    padding: 2px 15px;
}

.openNav-main-container-yoma {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 20px;
}

.allbooks-list-header-yoma {
    margin-top: 0px;
    height: 0px;
    font-family: Heebo;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #575757;
    margin-bottom: -10px;
}

.openNav-allbooks-Chosen-masechet {
    display: flex;
    width: 100%;

    flex-direction: column;
    align-items: center;

    position: relative;
    font-family: Heebo;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #151515;
}

.openNav-allbooks-list-yoma {
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    left: 22px;
    display: block;
}


.openNav-allbooks-container-yoma {
    display: flex;
    width: 84%;
    justify-content: center;
    margin-top: 2%;
    position: relative;
    right: 8%;
}

.Breadcrumbs-yoma {
    position: relative;
    right: 20%;
    width: 40%;
    top: 20px;
    height: 55px;
}

.Breadcrumbs-yoma-mobile {
    position: relative;
    top: 20px;
    height: 55px;
}

.openNav-bookChosen-container-wrapper-yoma {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 100;
    top: 40px;
}

.form-page-connect {
    position: relative;
    width: 298px;
    height: 37px;
    right: 20px;
    top: 40px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
}

.form-page-connect-yoma-bunner {
    position: relative;
    width: 80%;
    height: 30px;
    right: 10%;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
    font-size: 10px;
}

.form-page-connect-yoma-bunner:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.form-page-connect-text-yoma-bunner {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 31px;
    color: #FFFFFF;
    text-align: center;
}

.openNav-main-advertising-yoma-icon {
    position: relative;
    border-radius: 24px;
}

.close-user-svg-container-yoma {
    position: absolute;
    top: 5px;
    left: 5px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.close-user-svg-container-yoma:hover {
    border-radius: 100%;
    background-color: #f0f0f0;
    z-index: 100;
}

.openNav-main-advertising-yoma {
    position: fixed;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 19%);
    background-color: #fff;
    z-index: 9999999;
    box-sizing: border-box;
    border-radius: 24px;
    width: 15%;
    top: 100px;
    left: 1%;
    font-size: 83.1%;
}

.openNav-main-advertising-yoma-mobile {
    position: fixed;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 19%);
    background-color: #fff;
    z-index: 9999999;
    box-sizing: border-box;
    border-radius: 24px;
    width: 70%;
    top: 220px;
    right: 15%;
    font-size: 83.1%;
}

.openNav-main-advertising-yoma-text {
    position: relative;
    width: 90%;
    right: 5%;
    text-align: justify;
    color: #656565;
}

.openNav-main-advertising-yoma-text-b {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    top: 10px;
    position: relative;
    font-size: 120%;
    color: #656565;
}

.openNav-main-advertising-yoma-text-2 {
    position: relative;
    width: 90%;
    right: 5%;
    top: 12px;
    text-align: justify;
    color: #656565;
}