.form-page-create-title-mobile {
    position: relative;
    width: 100%;
    text-align: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #37A09D;
    height: 75px;
    top: 32px;
}

.form-page-create-line-mobile {
    position: relative;
    width: 80%;
    height: 0px;
    border: 0.75px solid #DBE2DE;
}

.form-page-create-first-name-mobile {
    position: relative;
    height: 33.5px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
    display: flex;
    background-color: #FFFFFF;
    margin-top: 10px;
}

.form-page-create-container-mobile {
    flex-wrap: wrap;
    position: relative;
    display: flex;
    justify-content: center;
}

.form-page-create-first-name-input-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    width: 73%;
    right: 14px;
    height: 28px;
}

.form-page-create-gender-mobile {
    position: relative;
    display: flex;
}

.form-page-create-gender-title-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    align-items: center;
    text-align: center;
    color: #656565;
}

.form-page-create-gender-title-mobile-1 {
    display: flex;
    margin-top: 5px;
    width: 47%;
    right: 12px;
    justify-content: space-evenly;
    position: relative;
}

.form-page-create-required-mobile {
    position: relative;
    height: 8px;
    color: #DA1212;
    top: 7px;
    right: 25px;
}

.form-page-create-gender-select-mobile {
    position: relative;
}

.form-page-create-CAPTCHA-mobile {
    position: relative;
    top: 20px;
    right: 20px;
    height: 100px;
    width: 100px;
}

.form-page-create-create-button-mobile {
    position: relative;
    height: 37px;
    top: 5px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    width: 90%;
    right: 4%;
}

.form-page-create-create-button-text-mobile {
    position: relative;
    height: 23px;
    top: 7px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    text-align: center;
    color: #FFFFFF;
}

.form-page-create-create-text-mobile {
    position: relative;

    height: 63px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    align-items: center;
    text-align: right;
    color: #494949;
    top: 20px;
    text-align: justify;
    width: 90%;
    right: 4%;
}

.dddddd {
    position: relative;
    width: 100%;
    justify-content: center;
}

.form-page-logo {
    position: relative;
    right: 40px;
    top: 40px;
    width: 100px;
    height: 60px;
}

.form-page-logo-word {
    position: relative;
    right: 40px;
    top: 50px;
    width: 100px;
    height: 150px;
}

.form-page-create-first-name {
    position: relative;
    width: 267px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
    display: flex;
}

.form-page-create-first-name:hover {
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.red-border {
    border: .85px solid red;
}

.form-page-create-required {
    position: relative;
    width: 21px;
    height: 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    align-items: center;
    text-align: center;
    color: #DA1212;
    right: 29px;
    top: 4px;
}

.form-page-create-first-name-input {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    right: 14px;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
}

.form-page-create-container {
    display: flex;
    flex-wrap: wrap;
    width: 620px;
    right: 40px;
}

.form-page-create-gender-title {
    position: relative;
    right: 30px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #656565;
    width: 80px;
}

.form-page-create-gender-select-1 {
    right: 100px !important;
}

.form-page-create-CAPTCHA {
    position: relative;
    top: 20px;
    right: 160px;
    height: 120px;
}

.form-page-create-create-button {
    position: relative;
    width: 551px;
    height: 37px;
    right: 20px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
}

.form-page-create-create-button:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.form-page-create-error {
    position: relative;
    width: 577px;
    height: 23px;
    top: -10px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: red;
}

.t {
    right: 5%;
    position: relative;
    top: 66px;
}

.form-page-create-create-button-text {
    position: relative;
    width: 362.91px;
    height: 23px;
    right: 251px;
    top: 7px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.form-page-create-create-text {
    position: relative;
    width: 571px;
    height: 63px;
    right: 33px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 20px;
    align-items: center;
    text-align: right;
    color: #494949;
    top: 20px;
}

.form-page-create-title {
    position: relative;
    right: 250px;
    height: 100px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
}

.form-page-create-gender-select-1__control {
    position: relative !important;
    border: 0.75px solid #38A09D !important;
    border-radius: 8px !important;
    width: 200px !important;
}

.form-page-create-gender-select {
    position: relative;
    width: 198px;
    height: 37px;
}

.form-page-create-gender-select-1-mobile__control {
    position: relative !important;
    border: 0.50px solid #38A09D !important;
    border-radius: 8px !important;
    width: 110px !important;
}

.form-page-create-gender {
    position: relative;
    width: 619px;
    height: 37px;
    display: flex;
    top: 8px;
}

.form-page-create-line {
    position: absolute;
    width: 539px;
    height: 0px;
    right: 27px;
    top: 72px;
    border: 0.75px solid #DBE2DE;
}

.form-page-connect-text {
    position: relative;
    top: 3px;
    right: 120px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.form-page-no-account {
    position: relative;
    width: 298px;
    height: 37px;
    right: 85px;
    top: 35px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
}

.form-page-visitor {
    position: relative;
    right: 120px;
    top: 50px;
    height: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
}


.form-page-create-gender-select-1__control {
    position: relative !important;
    border-radius: 8px !important;
    width: 200px !important;
}

.form-page-create-gender-select-1__option:hover {
    background: #f8f8f8 !important;
}

.form-page-create-gender-select-1__option--is-selected {
    background: #38A09D !important;
}

.form-page-create-gender-select-1__control--is-focused {
    position: relative !important;
    border-radius: 8px !important;
    border-color: #38A09D !important;
    box-shadow: 0 0 0 1px #38A09D !important;
    width: 200px !important;
    height: 5px !important;
    border: 0px !important;
    background: #f8f8f8 !important;
}
