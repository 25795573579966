.openNav-top-item-text {
  width: 89px;
  height: 28px;
  font-family: Heebo;
  font-size: 18.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #151515;
  cursor: pointer;
}

.openNav-top-item-divider {
  width: 8px;
  height: 28px;
  font-family: Heebo;
  font-size: 18.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #b7b7b7;
  margin-right: 9px;
  margin-left: 9px;
}

.openNav-talmud {
  margin-right: 0px;
  white-space: nowrap;
}

.openNav-choose-subject-container {
  display: flex;
  justify-content: center;
  margin-top: 2%;
  flex-wrap: wrap;
  position: relative;
}

.openNav-choose-subject-container-mobile {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.bookChosen-current-item:hover {
  border: solid 2px #49ccc8;
  border-radius: 4px;
}

.bookChosen-current-item {
  border: solid 2px #38A09D;
}

.openNav-select-dropdown {
  font-family: Heebo, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
  background: none;
  width: 100%;
  margin-bottom: 30px;
  position: sticky;
}

.MuiTypography-root {
  position: relative;
}

.react-select-container {
  width: 200px;
  font-family: Heebo, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
  margin-bottom: 15px;
}

.select-chapter-openNav {
  display: flex;
  justify-content: center;
}

.MuiCircularProgress-svg {
  color: #38A09D;
}