.page-dont-found-container{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    font-weight: bold;
    flex-direction: column;

}

.notFound-buttons-container{
margin-top: 30px;
}

.notFound-button{
    width: 168px;
    height: 46px;
    border-radius: 4px;
    background-color: #38A09D;
    color: white;
    font-family: Heebo,sans-serif;
    font-size: 1.1rem;
    margin:5px;
    cursor: pointer;
    pointer-events: all;
}