.show-sfarim {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px);
    width: 100%;
    position: relative;
}

.txt-container {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    cursor: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.txt-container::-webkit-scrollbar {
    width: 9px;
    height: 1px;
    background-color: #e5e7e7;
}

.txt-container::-webkit-scrollbar-thumb {
    background: #798383;
}

.txt-commentary-container-selected {
    box-sizing: border-box;
    border-right: 15px solid #38A09D82;
    width: 100%;
    height: auto;
    padding: 10px 15px 30px 30px;
}

.txt-commentary-container {
    border-right: 15px solid #FAF6F6;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 0px 15px 0px 30px;
    cursor: pointer;
}

.txt-commentary-container:hover {
    border-right: 15px solid #e8e8e8;
}

.txt-commentary-container:last-child {
    padding-bottom: 60px;
}

.txt-commentary-container-selected:last-child {
    padding-bottom: 60px;
}

.commentary-to-show {
    display: flex;
    flex-wrap: wrap;
    width: 408px;
    max-height: 185px;
    min-height: 50px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 20px 30px 20px;
    border-bottom: solid 1px rgba(151, 151, 151);
    cursor: auto;
    box-sizing: border-box;
}

.commentary-to-show::-webkit-scrollbar {
    width: 9px;
    height: 1px;
    background-color: #e5e7e7;
}

.commentary-to-show::-webkit-scrollbar-thumb {
    background: #798383;
}

.commentary-item-none-selected {
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    border: solid 1px #979797;
    font-family: Heebo, serif;
    font-size: 17px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: right;
    color: #585858;
    box-sizing: border-box;
    margin: 5px;
    height: fit-content;
}

.commentary-item-none-in-page {
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    border: dashed 1px #979797;
    font-family: Heebo, serif;
    font-size: 17px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: right;
    color: #585858;
    box-sizing: border-box;
    margin: 5px;
    height: fit-content;
}

.commentary-item-selected {
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    border: solid 1px #38A09D;
    font-family: Heebo, serif;
    font-size: 17px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: right;
    color: #ffffff;
    box-sizing: border-box;
    margin: 5px;
    background-color: #38A09D;
    height: fit-content;
}

.commentary-item-selected:hover {
    background-color: #48b3af;
}

.commentary-item-none-selected:hover {
    background-color: #fcfcfc;
}

.commentary-item-none-in-page:hover {
    background-color: #fcfcfc;
}

.return-choose-sfarim:hover {
    background-color: #fcfcfc;
}

.return-choose-sfarim {
    cursor: pointer;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    border: solid 1px #38A09D;
    font-family: Heebo, serif;
    font-size: 17px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    color: #585858;
    box-sizing: border-box;
    margin: 5px;
    width: 36px;
    box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.4);
    text-align: center;
    height: fit-content;
}

.plus-svg-show-sfarim path {
    color: #38A09D;
}

.none-txt-commentary-vs-selected-txt-unit {
    width: 400px;
    padding: 8px 10px 10px;
    background-color: #38A09D;
    position: sticky;
    top: 0;
    box-sizing: border-box;
    animation-name: appear;
    animation-duration: 0.5s;
}

@keyframes appear {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0.25;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.none-txt-commentary-vs-selected-txt-unit-text {
    height: 25px;
    width: 100%;
    font-family: Heebo;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.12px;
    text-align: center;
}

.container-checkbox {
    display: block;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 48px;
    font-family: Heebo, serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.42;
    letter-spacing: 0.13px;
    text-align: right;
    color: #38A09D;
}

.container-checkbox input {
    position: absolute;
    opacity: 0;
}

.container-checkbox .checkmark {
    position: absolute;
    top: 16px;
    left: 362px;
    height: 12px;
    width: 12px;
    background-color: #cccccc94;
    border-radius: 2px;
    border: solid 0.8px #38A09D;
    box-sizing: border-box;
}

.container-checkbox input:checked~.checkmark {
    background-color: #38A09D;
}

.container-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-checkbox input:checked~.checkmark:after {
    display: block;
}

.container-checkbox .checkmark:after {
    left: 3.5px;
    top: 1px;
    width: 2px;
    height: 5px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

@media screen and (max-width: 450px) {
    .commentary-item-selected {
        font-size: 10px;
    }

    .commentary-item-none-selected {
        font-size: 10px;
    }

    .return-choose-sfarim {
        font-size: 10px;
    }

    .commentary-to-show {
        max-height: 60px;
        min-height: 20px;
        width: 100%;
    }
}