.book-page-full-container {
    display: flex;
    width: 100%;
    height: calc(97vh - 90px);
    top: 113px;
    position: relative;
}

.mw-parser-output {
    position: relative;
    width: 83%;
    text-align: justify;
    height: 100%;
    overflow: scroll;
}

a.mw-ui-icon.mw-ui-icon-element.mw-ui-icon-minerva-edit-enabled.edit-page {
    display: none;
}

.book-page-container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
}

.book-page-container-yer {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.book-page-container-t {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.tanach-title-new-book-continer-continer {
    height: 100px;
    justify-content: center;
}

.book-page-footer {
    position: relative;
    height: 20px;
    width: 10px;
}

.book-page-container-t-mobile {
    padding-top: 40px;
}

.book-page-container-open-right {
    display: flex;
    position: relative;
    height: 100%;
    width: 80%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
    right: 20%;
}

.book-page-container-open-right-yer {
    position: relative;
    height: 100%;
    width: 70%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
    right: 25%;
}

.book-page-container-open-left {
    position: relative;
    height: 100%;
    width: 79%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
    right: 100px;
}

.book-page-container-open-left-yer {
    position: relative;
    height: 100%;
    width: 70%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
    right: 10px;
}

.book-page-container-open-left-shul {
    position: relative;
    height: 100%;
    width: 79%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
}

.book-page-container-open-right-tanach {
    position: relative;
    height: 100%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
    width: 80%;
    right: 300px;
}

.book-page-container-open-left-tanach {
    display: flex;
    position: relative;
    height: 100%;
    width: 79%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 5px;
}

.book-page-panel-header {
    position: relative;
    top: 38px;
    right: 15px;
    width: 30px;
    height: 29px;
    transform: rotate(-90deg);
    opacity: 0.74;
    font-family: Heebo, sans-serif;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: center;
    color: #252525;
    user-select: none;
    white-space: nowrap;
}

.book-page-panel.section-panel {
    z-index: 5;
}

.ssssssss:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}

.panel-plus-sign-svg-right {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: -8px;
    background: #37A09D;
    box-shadow: 0.5px 0px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(180deg);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
    display: flex;
}

.panel-plus-sign-svg-right-empty {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: -8px;
    background: #989e9e;
    box-shadow: 0.5px 0px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(180deg);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
    display: flex;
}

.panel-plus-sign-svg-right:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.panel-plus-sign-svg-right-open {
    position: relative;
    width: 52px;
    height: 52px;
    top: 15px;
    right: -8px;
    background: #37A09D;
    box-shadow: 0.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(180deg);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
}

.panel-plus-sign-svg-right-open-empty {
    position: relative;
    width: 52px;
    height: 52px;
    top: 15px;
    right: -8px;
    background: #989e9e;
    box-shadow: 0.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    transform: rotate(180deg);
    border-radius: 12px;
    cursor: pointer;
    display: flex;
}

.panel-plus-sign-svg-right-open:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.book-page-main-text {
    width: 400px;
    height: 719px;
    font-family: Mekorot-Vilna, serif;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: justify;
    color: #151515;
    margin: 0 50px;
}

.book-page-panel {
    width: 60px;
    min-width: 60px;
    height: 100%;
    box-shadow: 0 2px 25px -13px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(151, 151, 151, 0.49);
    background-color: #FAF6F6;
    cursor: pointer;
}

.book-page-rashi-main {
    width: 260px;
    font-family: Mekorot-Rashi, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 0.08px;
    text-align: justify;
    color: #151515;
}

.book-page-tosafot-main {
    width: 260px;
    font-family: Mekorot-Rashi, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 0.08px;
    text-align: justify;
    color: #151515;
}

.text-unit:hover {
    cursor: pointer;
    background: rgba(198, 224, 224, 0.68);
    background-blend-mode: multiply;
}

.text-unit::after {
    content: '  ';
    display: inline-block;
}

.text-unit-selected::after {
    content: '  ';
    display: inline-block;
}



.text-unit-shulchan-srukh small {
    font-family: Mekorot-Rashi, sans-serif;
    font-size: 28px;
}

.text-unit-shulchan-srukh b {
    line-height: 79px;
    padding-right: 25%;
    font-size: 35px;
    text-align: center;
}


.shulchan-srukh-title {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-bottom: 17px;
    font-size: 25px;
}

.text-unit-shulchan-srukh1 b {
    text-align: center;
    width: 100%;
    position: relative;
    height: 100px;
    font-size: 26.5px;
}

.text-unit-shulchan-srukh1 big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
}

.text-unit-shulchan-srukh-selected1 big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
    background: rgba(176, 209, 208, 0.68);
}

.text-unit-shulchan-srukh1-m big b {

    position: relative;
    font-weight: 500;
    display: block;
}

.text-unit-shulchan-srukh1-m {
    font-size: 20px;
}

.text-unit-Liturgy b {
    text-align: center;
    width: 100%;
    position: relative;
    height: 100px;
    font-size: 26.5px;
}

.text-unit-Liturgy {
    position: relative;
    display: flow-root;
}

.text-unit-Liturgy big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
}

.text-unit-Liturgy-selected b {
    text-align: center;
    width: 100%;
    position: relative;
    height: 100px;
    font-size: 26.5px;
}

.text-unit-Liturgy-selected big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
}

.text-unit-Liturgy-m {
    position: relative;
    display: flow-root;
    margin-top: 5px;
    top: 30px;
    font-size: 20px;
}

.text-unit-Liturgy-m big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
}

.text-unit-Liturgy-selected-m b {
    text-align: center;
    width: 100%;
    position: relative;
    height: 100px;
    font-size: 26.5px;
}

.text-unit-Liturgy-selected-m big b {
    text-align: center;
    width: 100%;
    position: relative;
    justify-content: center;
    font-weight: 500;
    font-size: 43px;
    display: block;
    height: 16px;
}

.text-unit-shulchan-srukh1 {
    position: relative;
}

.text-unit-shulchan-srukh1 small {
    position: relative;
    font-size: 15px;
}

.text-unit-shulchan-srukh-selected1 small {
    position: relative;
    font-size: 15px;
}

.text-unit-shulchan-srukh1 small small {
    position: relative;
    font-size: 10px;
}

.text-unit-Liturgy-selected small {
    position: relative;
    font-size: 15px;
}

.text-unit-Liturgy-selected small small {
    position: relative;
    font-size: 10px;
}

.text-unit-Liturgy-selected-m small {
    position: relative;
    font-size: 15px;
}

.text-unit-Liturgy-selected-m small small {
    position: relative;
    font-size: 10px;
}

.text-unit-Liturgy {
    position: relative;
    top: 30px;
}

.text-unit-Liturgy small {
    position: relative;
    font-size: 15px;
}

.text-unit-Liturgy small small {
    position: relative;
    font-size: 10px;
}

h1 {
    font-size: 20px;
    line-height: 10px;
}

.text-unit-shulchan-srukh1:hover {
    cursor: pointer;
    background: rgba(212, 230, 229, 0.68);
    background-blend-mode: multiply;
}

.text-unit-Liturgy:hover {
    cursor: pointer;
    background: rgba(212, 230, 229, 0.68);
    background-blend-mode: multiply;
}

.text-unit-Talmud b {
    font-family: PFT_vilna Bold;
}

.text-unit-Talmud-selected b {
    font-family: PFT_vilna Bold;
}

.text-unit-shulchan-srukh-selected small {
    font-family: Mekorot-Rashi, sans-serif;
    font-size: 28px;
}

.aaaaaa {
    text-align: center;
    line-height: 50px;
    font-size: 2.3rem;
}

.text-unit-shulchan-srukh-selected b {
    line-height: 79px;
    padding-right: 25%;
    font-size: 38px;
    text-align: center;
}

.text-unit-selected {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
}

.text-unit-shulchan-srukh-selected {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
}


.text-unit-shulchan-srukh-selected1 {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
    position: relative;
}
.text-unit-shulchan-srukh-selected1-m {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
    position: relative;
    font-size: 20px;
}



.text-unit-Liturgy-selected {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
    position: relative;
    top: 30px;
}

.text-unit-Liturgy-selected-m {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
    position: relative;
    top: 30px;
}

.text-unit-Talmud-selected {
    cursor: pointer;
    background: rgba(176, 209, 208, 0.68);
    background-blend-mode: multiply;
}

.panel-expanded {
    position: relative;
    width: 400px;
    opacity: 1;
    overflow: hidden;
    height: 100%;
    box-shadow: 0 2px 25px -13px rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    z-index: 3;
    background-color: #FAF6F6;
}

.panel-right-side-container {
    display: flex;
    position: fixed;
    right: 0;
    z-index: 100;
    height: 100%;
    min-width: 124px;
    max-width: 462px;
    top: 113px;
}


.panel-left-side-container {
    display: flex;
    position: fixed;
    left: 0;
    height: 101%;
    top: 113px;
    z-index: 2;
}

.panel-expanded-header-close-sign {
    cursor: pointer
}

.display-none {
    display: none;
}

.panelnone {
    width: 0;
    opacity: 0;
    pointer-events: none;
}

.panel-expanded-header {
    align-items: center;
    display: flex;
    width: 401px;
    height: 65px;
    justify-content: space-between;
    padding: 16px 20px 20px;
    box-sizing: border-box;
    position: fixed;
    background-color: white;
    z-index: 4;
    border-left: solid 1px rgba(151, 151, 151, 0.49);
}

.panel-expanded-header-b {
    align-items: center;
    display: flex;
    width: 401px;
    height: 65px;
    justify-content: space-between;
    padding: 16px 20px 20px;
    box-sizing: border-box;
    position: fixed;
    background-color: #FAF6F6;
    z-index: 4;
    border-left: solid 1px rgba(151, 151, 151, 0.49);
}

.panel-expanded-header-title {
    font-family: Heebo;
    opacity: 0.74;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.15px;
    text-align: center;
    color: #252525;
    padding-left: 26px;
}

.panel-expanded-section {
    position: relative;
    width: 400px;
    opacity: 1;
    overflow: hidden;
    height: calc(100vh - 80px);
    box-shadow: 0 2px 25px -13px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(151, 151, 151, 0.49);
    cursor: auto;
    z-index: 3;
    background-color: #FAF6F6;
    overflow-y: scroll;
}

.panel-expanded-section-sfarim {
    position: relative;
    width: 400px;
    opacity: 1;
    overflow: hidden;
    transition: all 0.4s ease-in-out;
    height: 100%;
    box-shadow: 0 2px 25px -13px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(151, 151, 151, 0.49);
    cursor: default;
    z-index: 3;
    background-color: #FAF6F6;
}

.panel-expanded-main {
    width: 350px;
    height: 100%;
    padding-right: 25px;
    padding-left: 25px;
}

.book-page-main-loading {
    margin-top: 200px;
    height: 200px;
    width: 200px;
}

.expended-container {
    position: relative;
    width: 100%;
    opacity: 1;
    overflow-y: scroll;
    height: 100%;
    box-shadow: 0 2px 25px -13px rgba(0, 0, 0, 0.5);
    border: solid 1px rgba(151, 151, 151, 0.49);
    cursor: default;
    z-index: 3;
    background-color: white;
    background: white;
}



.expended-container1 {
    position: absolute;
    width: 402px;
    height: 84%;
    left: 0px;
    top: 0px;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
}

.expended-container1::-webkit-scrollbar {
    width: 9px;
    height: 1px;
    background-color: #e5e7e7;
}

.expended-container1::-webkit-scrollbar-thumb {
    background: #798383;
}

.expended-container1-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
    overflow-y: scroll;
}

.section-content {
    position: relative;
    top: 65px;
    width: 400px;
    padding-bottom: 120px;
    height: 85%;
}

.section-content-biyur {
    position: relative;
    top: 65px;
    width: 400px;
    padding-bottom: 120px;
    height: fit-content;
}

.section-content-sfarim {
    position: relative;
    height: calc(100% - 65px);
}

.left-row {
    margin-left: 9.5px;
    margin-right: auto;
}

.right-row {
    margin-left: auto;
    margin-right: 9.5px;
}

.middle-row {
    margin-left: auto;
    margin-right: auto;
}

.rashi-new, .tosafot-new {
    position: absolute;
    font-family: PFT_Rashi, sans-serif;
    font-size: 16.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    text-align: justify;
    color: #151515;
    width: 850px;
    pointer-events: none;
    margin-bottom: 60px;
    word-spacing: -3px;
    top: 80px;
    white-space: pre-wrap;
    z-index: 2;
}

.rashi-new-row, .tosafot-new-row {
    height: 19.8px;
    pointer-events: all;
}

.rashi-thirtySeven-row, .tosafot-thirtySeven-row {
    width: 37.63%;
}

.rashi-full-row, .tosafot-full-row {
    width: 98%
}

.rashi-half-row, .tosafot-half-row {
    width: 48%
}

.rashi-third-row, .tosafot-third-row {
    width: 28%
}

.tosafot-third-row-small {
    width: 11.88172%
}

.rashi-stretch-row, .tosafot-stretch-row {
    width: 70.43%
}

.rashi-halfpage-row, .tosafot-halfpage-row {
    width: 47.92%
}

.rashi-new-row::after, .tosafot-new-row:after {
    content: '';
    display: inline-block;
    width: 100%;
}

.gmara-new-row {
    height: 22.7px;
    pointer-events: all;
}

.gmara-full-row {
    width: 98%
}

.gmara-half-row {
    width: 38%;
}

.gmara-thirtySeven-row {
    width: 36.63%;
}

.gmara-third-row {
    width: 26%
}

.gmara-half-third-row {
    width: 17%;
    left: 93px;
    position: relative;
}

.gmara-halfpage-row {
    width: 47.92%;
}

.gmara-stretch-row {
    width: 68%;
}

.gmara-new-row::after {
    content: '';
    display: inline-block;
    width: 100%;
}

.gmara-new {
    position: absolute;
    font-family: PFT_vilna;
    font-size: 20.3px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 25px;
    letter-spacing: -.60px;
    text-align: justify;
    color: #151515;
    width: 850px;
    pointer-events: none;
    margin-bottom: 60px;
    top: 80px;
    white-space: pre-wrap;
}

iframe {
    border: none;
}

.rashi-new-row big {
    font-size: 15px;
    font-weight: 715;
    line-height: 14px;
    font-family: PFT_vilna;
}

.tosafot-new-row big {
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
}

.tosafot-new-row strong {
    font-size: 21px;
    font-weight: 700;
    line-height: 15px;
    font-family: PFT_vilna;
}

.tosafot-half-third-row {
    width: 17%;
    right: 93px;
    position: relative;
}

.rashi-half-third-row {
    width: 10%;
    right: 151px;
    position: relative;
}

.tosafot-half-special-row {
    width: 17%;
    right: 93px;
    position: relative;
}

.rashi-half-special-row {
    width: 25%;
    right: 120px;
    position: relative;
}

.gmara-title-new-book {
    position: absolute;
    height: 138px;
    top: -170px;
    right: 285px;
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PFT_vilna Bold;
}

.gmara-title-hea {
    display: contents;
    width: 100%;
}

.tanach-title-new-book-continer {
    position: relative;
    width: 100%;
}

.tanach-title-new-book {
    position: relative;
    top: 32px;
    text-align: justify;
    font-family: PFT_vilna Bold;
    font-size: 33px;
    width: 80%;
}

.Prophets {
    min-width: 100%;
    right: 1%;
}

.Writings {
    min-width: 100%;
    right: 1%;
}

.Prophets-header {
    min-width: 56.5%;
}

.Writings-header {
    min-width: 56.5%;
}

.tanach-title-new-book-shulchan-arukh {
    position: relative;
    width: 70%;
    right: 15%;
    text-align: justify;
    font-family: PFT_vilna;
    font-size: 1.5rem;
    cursor: pointer;
    top: 30px;
}

.tanach-title-new-book-shulchan-arukh small {
    font-size: 17.5px;
}

.tanach-title-new-book-mishnah_Berurah {
    position: relative;
    width: 70%;
    right: 15%;
    text-align: justify;
    font-family: PFT_vilna;
    font-size: 25px;
    cursor: pointer;
}

.tanach-title-new-book-yer {
    position: relative;
    width: 70%;
    text-align: justify;
    font-family: PFT_vilna Bold;
    font-size: 2.1rem;
    cursor: pointer;
}

.tanach-title-new-book-Talmud {
    position: relative;
    width: 60%;
    right: 20%;
    text-align: justify;
    font-family: PFT_vilna;
    font-size: 1.6rem;
    cursor: pointer;
}

.tanach-title-new-book-Liturgy {
    position: relative;
    width: 90%;
    right: 5%;
    text-align: justify;
    font-family: PFT_vilna;
    font-size: 1.6rem;
    cursor: pointer;
    top: 20px;
}

.Commentary {
    position: relative;
    width: 60%;
    right: 20%;
    text-align: justify;
    font-family: PFT_vilna;
    font-size: 1.6rem;
    cursor: pointer;
}

.center {
    margin-right: 50%;
}

.center-onke {
    margin-right: 44%;
    line-height: 40px;
}

.tanach-title-new-book-rashi {
    position: relative;
    top: 40px;
    width: 57%;
    right: 22%;
    text-align: justify;
    font-family: PFT_Rashi, sans-serif;
    font-size: 1.6rem;
    column-count: 2;
    line-height: 34.5px;

}

.tanach-title-new-book-rashi-header {
    position: relative;
    top: 40px;
    width: 57%;
    right: 22%;
    text-align: center;
    font-family: PFT_vilna Bold;
    font-size: 1.8rem;
}

.tanach-title-new-book-onk {
    position: relative;
    width: 20%;
    text-align: justify;
    font-family: PFT_vilna Bold;
    font-size: 15px;
    padding-right: 25px;
}

.pot-title-new-book-onk {
    position: relative;
    width: 25%;
    left: 3%;
    text-align: justify;
    font-family: PFT_vilna Bold;
    font-size: 1.7rem;
}

.gmara-title-header {
    position: relative;
    font-size: 2.5rem;
}

.new-gemara-logo-svg {
    position: absolute;
    width: 290px;
    opacity: 0.8;
}

.margin-wrapper-main {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #F9FAFA;
}

.mishpat-footer-container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    height: 55px;
    text-align: center;
    border-top: solid 1px #979797;
    box-sizing: border-box;
    top: auto;
}

.mishpat-footer-container-t {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    height: 55px;
    text-align: center;
    border-top: solid 1px #979797;
    box-sizing: border-box;
    padding-top: 20px;
    top: 10px;
}

.mishpat-footer-container-yer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    height: 70px;
    text-align: center;
    border-top: solid 1px #979797;
    box-sizing: border-box;
    padding-top: 20px;
    top: 10px;
}

.mishpat-footer-container-yer {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    height: 70px;
    text-align: center;
    border-top: solid 1px #979797;
    box-sizing: border-box;
    padding-top: 20px;
    top: 10px;
}

.mishpat-footer {
    height: fit-content;
    font-family: Heebo, serif;
    font-size: 13.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.09px;
    text-align: center;
    color: #484848;
    cursor: pointer;
    margin: 0 10px;
    text-decoration: none;
    position: relative;
    top: 10px;
}

.mishpat-footer:hover {
    text-decoration: underline;
}

.show-all {
    opacity: 0.5;
    cursor: default;
}

.show-all-pid-open {
    opacity: 0.3;
    cursor: default;
}

.user-profile-on-container {
    height: 500px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 112px;
    width: 100%;
}

.user-profile-none-container {
    display: none;
}

.user-profile-on {
    position: relative;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    z-index: 9999999;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
}

.user-profile-on-create {
    position: relative;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    z-index: 9999999;
    box-sizing: border-box;
    width: 603px;
    height: 522px;
    border-radius: 14px;
    display: flex;
}

.title-name-book-page-1 {
    position: relative;
    right: -32px;
}

.pid-on {
    position: relative;
    width: 800px;
    max-height: 720px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    z-index: 9999999;
    border-bottom: solid 8px #38A09D;
    box-sizing: border-box;
}

.user-profile-close {
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.close-user-svg {
    cursor: pointer;
    padding-top: 9px;
}

.close-user-svg-mobile {
    cursor: pointer;
    padding-top: 9px;
    position: relative;
    right: 86%;
    top: 15px;
    z-index: 2000;
}

.iframe-user-profile {
    height: calc(100% - 70px);
    width: 100%;
}

.text-unit-index {
    position: relative;
    left: 15px;
    top: 25px;
    width: 0px;
    color: red;
    font-size: 14px;
}

.close-user-svg-container {
    position: relative;
    left: 13px;
    top: 25px;
    height: 34px;
    width: 34px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.close-user-svg-container:hover {
    border-radius: 100%;
    background-color: #f0f0f0;
}

.panel-right-side-container-none {
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 14%;
    padding-top: 10px;
}

.fixed {
    position: fixed;
    width: 100%;
    bottom: -25px;
    background-color: #f4f4f4;
}

.continer {
    display: flex;
    width: 57%;
    right: 22%;
    position: relative;
    justify-content: center;
}

.title-name-book-page {
    position: relative;
    text-align: center;
}

@media screen and (max-width: 850px) {
    .rashi-new, .tosafot-new {
        width: 94%;
        font-size: 15px;
        word-spacing: -6px;
        line-height: 0px;
    }

    .gmara-new {
        width: 94%;
        font-size: 19.5px;
        line-height: 0px;
        word-spacing: -6px;
    }

    .rashi-full-row, .tosafot-full-row {
        width: 94.5%
    }

    .tanach-title-new-book-rashi {
        width: 90%;
        margin-right: -70px;
    }

    .tanach-title-new-book-onk {
        width: 30%;
        position: relative;
        margin-right: 25%;
        font-size: 1.25rem;
        left: 33px;
    }

    .tanach-title-new-book {
        width: 70%;
        position: relative;
        margin-right: -10%;
    }

    .mishpat-footer-container {
        position: absolute;
        width: 100%;
    }

    .panel-plus-sign-svg-right {
        right: 0px;
    }

    .tanach-title-new-book-continer {
        display: flex;
        position: static;
    }

    .rashi-new big {
        font-size: 12.5px;
        line-height: 0px
    }

    .tosafot-new-row big {
        font-size: 13px;
        line-height: 0px;
    }

    .tosafot-new-row strong {
        line-height: 0px;
        font-size: 16px;
    }

    .continer {
        display: flex;
        width: 100%;
        margin-right: 0px;
    }

    .panel-right-side-container {
        right: -124px;
    }

    .tanach-title-new-book-shulchan-arukh {
        width: 91%;
        right: 4%;
        font-size: 1.6rem;
    }

    .text-unit-shulchan-srukh small {
        font-size: 22px;
    }
}

@media screen and (max-width: 800px) {
    .tanach-title-new-book-onk {
        width: 50%;
        position: relative;
        margin-right: 29%;
        font-size: 1.25rem;
        left: 30px;
    }

    .tanach-title-new-book {
        width: 90%;
        position: relative;
        margin-right: -30%;
    }

    .continer {
        display: flex;
        width: 100%;
        margin-right: 120px;
    }

    .new-gemara-logo-svg {
        width: 120%;
    }

    .gmara-title-new-book {
        position: absolute;
        height: 138px;
        top: -185px;
        right: 230px;
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PFT_vilna Bold;
    }

    .book-page-full-container {
        height: calc(100vh - 130px);
        z-index: 9999;
        top: 55px;
    }
}

@media screen and (max-width:580px) {
    .tanach-title-new-book-onk {
        width: 50%;
        position: relative;
        margin-right: 29%;
        font-size: 1.25rem;
        left: 30px;
    }

    .continer {
        display: flex;
        width: 100%;
        margin-right: 0px;
    }
}

@media screen and (max-width: 450px) {
    .rashi-new-row, .tosafot-new-row {
        height: 8.4px;
        font-weight: 500;
        word-spacing: -0.7px;
        letter-spacing: -0.1px;

    }

    .gmara-stretch-row {
        width: 66%;
    }

    .rashi-half-row, .tosafot-half-row {
        width: 47%;
    }

    .book-page-full-container {
        height: calc(94vh - 130px);
        z-index: 9999;
        top: 44px;
    }

    .title-name-book-page-1 {
        font-size: 18px;
        padding-top: 15px;
    }

    .rashi-new, .tosafot-new {
        width: 94%;
        font-size: 6.9px;
        word-spacing: -2.1px;
        line-height: 0px;
        top: 60px;
    }

    .gmara-new {
        width: 94%;
        font-size: 8.5px;
        line-height: 0px;
        word-spacing: -1px;
        letter-spacing: -0.1px;
        font-weight: 400;
        top: 0px;
    }

    .gmara-new-row {
        height: 9.5px;
    }

    .gmara-new-title-mobile {
        top: -105px;
    }

    .gmara-title-header {
        font-size: 1.5rem;
    }

    .new-gemara-logo-svg {
        width: 50%;
    }

    .rashi-new big {
        font-size: 6.8px;
        word-spacing: -2.1px;
        line-height: 0px;
    }

    .tosafot-new-row strong {
        font-size: 8.6px;
        word-spacing: -2.1px;
    }

    .tosafot-new-row big {
        font-size: 8.6px;
        word-spacing: -2.1px;
    }

    .tanach-title-new-book-rashi {
        font-size: 1.1rem;
        top: 40px;
    }

    .tanach-title-new-book-onk {
        font-size: 1rem;
        right: -107px;
        width: 161px;
        top: 15px;
        left: 10px;
    }

    .tanach-title-new-book {
        font-size: 1.4rem;
        width: 69%;
        margin-right: -17%;
    }

    .mishpat-footer-container {
        position: fixed;
        width: 100%;
        bottom: 0;
        height: 55px;
    }

    .mishpat-footer-container-t {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        height: 55px;
        text-align: center;
        border-top: solid 1px #979797;
        box-sizing: border-box;
    }

    .panel-plus-sign-svg-right {
        right: 0px;
    }

    .tanach-title-new-book-continer {
        display: grid;
    }

    .continer {
        display: flex;
        width: 100%;
        margin-right: 0px;
        left: 10px;
    }

    .gmara-title-new-book {
        position: absolute;
        height: 138px;
        top: -115px;
        right: 11.5%;
        width: 77%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PFT_vilna Bold;
    }

    .panel-expanded-header {
        width: 100%;
        height: 35px;
        padding-top: 5px;
    }

    .section-content-sub {
        width: 100%;
    }

    .section-content {
        position: relative;
        top: 65px;
        width: 100%;
        padding-bottom: 120px;
        height: 85%;
    }

    .panel-expanded-header-close-sign {
        display: none;
    }

    .panel-expanded-header-b {
        height: 47px;
        width: 99.7%;
    }

    .panel-expanded-header-title {
        font-size: 13px;
    }
}

@media screen and (max-width: 350px) {
    .continer {
        display: flex;
        width: 100%;
        margin-right: 10px;
        left: 10px;
    }

    .tanach-title-new-book-onk {
        font-size: 1rem;
        right: -30px;
        width: 151px;
        top: 83px;
        left: 15px;
    }

    .tanach-title-new-book {
        font-size: 1.20rem;
        margin-right: -30px;
        width: 190px;
    }

    .title-name-book-page-1-teanach {
        width: 59px;
    }
}