.wellcome-user-mobile-wellcome {
    position: relative;
    width: 100%;
    height: 77px;
    top: 22px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
  
    color: #494949;
    justify-content: center;
}

.wellcome-user-mobile-select {
    position: relative;
    width: 100%;
    height: 66.71px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
   
    color: #494949;
    justify-content: center;
}

.wellcome-user-mobile-botton {
    position: relative;
    width: 136px;
    height: 135px;
    margin: 8px;
    background: #FFFFFF;
    border: 1px solid #E2DBDB;
    box-sizing: border-box;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
    display: grid;
    text-align: center;
    justify-content: center;
}

.wellcome-user-mobile-botton-icon {
    height: 40px;
    position: relative;
    top: 10px;
    z-index: 100;
    display: 'flex';
    height: 'inherit';
    width: 'inherit';
}
.wellcome-user-mobile-botton-icon-4 {
    height: 40px;
    position: relative;
    top: -4px;
    z-index: 100;
    display: 'flex';
    height: 'inherit';
    width: 'inherit';
}

.wellcome-user-mobile-botton-text {
    position: relative;
    width: 90px;
    height: 67px;
    top: 15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 88.7%;
    
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
}
.wellcome-user-mobile-botton-text-a {
    position: relative;
    width: 90px;
    height: 67px;
    top: 30px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 88.7%;
    
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
}

.wellcome-user-mobile-botton-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    text-align: center;
    position: relative;
    justify-content: center;
}