.wellcome-icon-mobile {
    position: relative;
    text-align: center;
}
@media (max-width: 767px){
    body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.wellcome-mobile {
    background: url('../../svg/imageBackgroundMobile.svg') no-repeat center center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    position: relative;
    justify-content: center;
    background-attachment: fixed;
    top: 66px;
}
.wellcome-mobile-FormPageCreateMobile {
    background: url('../../svg/imageBackgroundMobile.svg') no-repeat center center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    justify-content: center;
    background-attachment: fixed;
    overflow-y: scroll;
}

.wellcome-text-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 99.7%;
    display: flex;
    width: 300px;
    text-align: center;
    color: #213139;
    height: 107px;
}

.wellcome-sub-text-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 117.7%;
    text-align: center;
    color: #213139;
    width: 300px;
    height: 60px;
}

.wellcome-icon-sub-mobile {
    position: relative;
    justify-content: space-around;
    display: flex;
    height: 140px
}

.wellcome-icon-sub-text-mobile {
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    width: 105px;
}

.wellcome-button-mobile {
    position: relative;
    width: 285px;
    height: 53px;
    background: #38A09D;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.wellcome-button-text-mobile {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: -717px;
}


.icon-height-mobile{
    position: relative;
    top: 4%;
    height: 25%;
}