.send-mail {
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;

}

.send-mail-main {
    top: 180px;
    width: 720px;
    position: fixed;
    z-index: 11111;
    background-color: white;
    border: 1px solid #DBE2DE;
    border-radius: 7px;
    min-height: 200px;
}

.send-mail-input-subject {
    position: fixed;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    width: 73%;
    right: 21px;
    height: 28px;
}

.send-mail-input-subject-from {
    position: fixed;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    width: 73%;
    right: 19px;
    height: 28px;
}

.send-mail-input-subject-text {
    position: fixed;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    width: 73%;
    right: 10px;
    height: 28px;
}

.send-mail-input-subject-title {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    height: 28px;
    background: #f8f8f8;
}

.send-mail-input-subject-title-text {
    right: 21px;
    position: relative;
    font-weight: 500;
}

.send-mail-submit {
    width: 81px;
    height: 24.19px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    position: relative;
    right: 628px;
    cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
}

.send-mail-submit-empty {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    color: #9fabaa;
    position: relative;
    right: 39%;
    cursor: pointer;
    justify-content: center;
    display: flex;
}

.send-mail-submit-text-empty {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #9fabaa;
    position: relative;
    right: 20px;
    top: 1px;
}

.send-mail-submit-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    position: relative;
}

.send-mail-submit:hover {
    background: #3fb1ad;
    -webkit-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.25);
}

.send-mail-submit-main {
    position: absolute;
    top: 317px;
    z-index: 999;
}

.loading-indicator-loadingsvg13 {
    position: relative;
    display: flex;
    top: 60px;
    margin: auto;
    justify-content: center;
    align-items: center;
    transform: scale(3);
    width: 50px;

}


.loading-indicator-send {
    position: absolute;
    transform: scale(0.4);

}

.loading-indicator-send-circle {
    z-index: 100;
}

.loading-indicator-send-text {
    position: absolute;
    font-size: 8px;
    margin-top: 40px;
    width: 200px;
    text-align: center;
}


.send-mail-input-subject-close-icon:hover {
    border-radius: 100%;
    background-color: #e7e5e5;
}

.send-mail-line {
    padding-right: 22px;
}