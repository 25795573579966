.panel-plus-sign-svg-right-text {
    top: 17px;
    position: relative;
    right: -8px;
    direction: ltr;
    transform: rotate(180deg);
    color: #FFFFFF;
    text-align: center;
    font-size: 13px;
}

.nav-arrow-button-left-close {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 8px;
    padding-top: 3px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}

.panel-expanded-header-title-sfarim-clean-all-txt {
    font-family: Heebo;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.1px;
    text-align: center;
    color: #38A09D;
    cursor: pointer;
    position: relative;
    top: 1px;
    width: 100%;
}

.panel-expanded-header-title-sfarim-clean-all-txt:hover {
    text-decoration: underline;
}

.panel-expanded-header-close-sign:hover {
    background-color: #c7c6c6;
    transition: all 0.3s ease-in-out;
}

.book-page-panel-header-s {
    position: relative;
    top: 150px;
    right: -10px;
    width: 52px;
    height: 190px;
    opacity: 0.74;
    background: #FFFFFF;
    -webkit-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
}

.book-page-panel-header-s-open {
    position: relative;
    top: 100px;
    right: -10px;
    width: 52px;
    height: 190px;
    background: #FFFFFF;
    -webkit-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
}

.book-page-panel-header-s:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.book-page-panel-header-s-open:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.book-page-panel-header-s-text {
    position: relative;
    right: 5px;
    height: 100%;
    justify-content: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    transform: rotate(-90deg);
}

.book-page-panel-header-s-text-open {
    position: absolute;
    width: 92px;
    height: 26px;
    top: 81px;
    right: -15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    transform: rotate(-90deg);
}