.OpenNavPage-background {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    background: #ffffff8e;
    box-shadow: 0px 0.5px 4px rgb(0 0 0 / 25%);
    justify-content: center;
    z-index: 99900;
    align-items: center;
}

.OpenNavPage-background-sub {
    height: 500px;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 112px;
    width: 100%;
}

.OpenNavPage-background-sub-sub {
    position: relative;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 19%);
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    z-index: 999999999999;
}

.OpenNavPage-background-sub-sub::-webkit-scrollbar {
    width: 12px;
    height: 1px;
    z-index: 10000;
}

.OpenNavPage-background-sub-sub::-webkit-scrollbar-thumb {
    background: #798383;
    z-index: 10001;

  }