.hidushim-menu-mobile {
    position: relative;
    justify-content: center;
}

.hidushim-menu-mobile-line-mobile {
    position: relative;
    height: 0px;
    border: 1px solid #B4CCCB;
}

.hidushim-menu-mobile-text {
    position: relative;
    width: 249px;
    height: 63px;
    top: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: #389F9C;
}

.mobile-line-mobile {
    position: relative;
    height: 80px;
}

@media screen and (max-width: 420px) {
    .mobile-line-mobile {
        position: relative;
        height: 25%;
    }
}