.work-title {
    padding: 30px 30px 30px 30px;
    background-color: #FFF;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #E4E6E9;
    border-radius: 30px 30px 0 0;
    position: relative;
    display: flex;
    width: 70%;
    box-shadow: 0 33px 30px 2px rgb(0 0 0 / 6%);
    font-size: 90%;
    line-height: 30%;
    cursor: pointer;
    justify-content: space-between;
}

.work-title:hover {
    background-color: rgb(240, 239, 239);
}

.work-title-middel {
    padding: 30px 30px 30px 30px;
    background-color: #FFF;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: #E4E6E9;
    position: relative;
    display: flex;
    width: 70%;
    box-shadow: 0 33px 30px 2px rgb(0 0 0 / 6%);
    font-size: 90%;
    line-height: 30%;
    cursor: pointer;
    justify-content: space-between;
}

.work-title-middel:hover {
    background-color: rgb(240, 239, 239);
}

.work-title-sub {
    padding: 30px 30px 30px 30px;
    background-color: #FFF;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: #E4E6E9;
    border-radius: 0px 0px 30px 30px;
    position: relative;
    width: 70%;
    box-shadow: 0 33px 30px 2px rgb(0 0 0 / 6%);
    font-size: 90%;
    line-height: 30%;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}

.work-title-sub:hover {
    background-color: rgb(240, 239, 239);
}

.work-title-sub-middel {
    padding: 0px 30px 0px 30px;
    background-color: #FFF;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: #E4E6E9;
    border-radius: 0px 0px 0px 0px;
    position: relative;
    justify-content: space-between;
    width: 70%;
    font-size: 85%;
    display: flex;
    color: #38A09D;
    cursor: pointer;
    z-index: 10;
}

.work-title-sub-middel-open {
    padding: 0px 30px 0px 30px;
    background-color: #FFF;
    border-style: solid;
    border-width: 1px 0 1px 0;
    border-color: #E4E6E9;
    border-radius: 0px 0px 30px 30px;
    position: relative;
    justify-content: space-between;
    width: 70%;
    font-size: 85%;
    display: flex;
    color: #38A09D;
    cursor: pointer;
    z-index: 10;
}

.work-title-sub-middel-open:hover {
    background-color: rgb(240, 239, 239);
}

.work-title-sub-middel-image-a2 {
    width: 75%;
    height: 10%;
    top: -64px;
    position: relative;
    left: 120px;
}

.work-title-sub-middel-image-a1 {
    width: 75%;
    height: 650px;
    top: -70px;
    position: relative;
}

.work-title-sub-middel:hover {
    background-color: rgb(240, 239, 239);
}

.poto {
    position: relative;
    text-align: revert;
    width: 30%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    top: 140px;
    right: 10%;
}

.Poto_01 {
    position: relative;
    width: 100%;
    height: 150%;
    top: -80px;
    right: 50px;
    z-index: 1;
}

.Poto_02 {
    position: relative;
    width: 100%;
    z-index: 1;
}

.poto-a2 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 35%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    right: 50px;
    top: 40px;
}

.poto-a3 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 40%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    right: 50px;
    top: 60px;
}

.poto-b1 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 65%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    right: 50px;
    top: 40px;
}

.poto-b2 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 65%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    left: 50px;
    top: 40px;
}

.poto-b3 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 65%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    right: 50px;
    top: 140px;
}

.poto-b4 {
    position: relative;
    z-index: 99;
    text-align: revert;
    width: 35%;
    font-size: 60%;
    height: 0px;
    line-height: 20px;
    right: 44px;
    top: 80px;
}

.poto-a2 b {
    color: #38A09D;
}

.work-title-sub-middel-main {
    background-color: #FFF;
    width: 74%;
    display: flex;
    height: 430px;
}

.work-title-sub-middel-main-open {
    background-color: #FFF;
    width: 74%;
    display: flex;
    border-radius: 00px 0px 30px 30px;
    min-height: 400px;
}

.work-title-sub-middel-helper {
    background-color: #38A09D;
    width: 400px;
    font-size: 19px;
    border-radius: 30px;
    color: #FFF;
    font-weight: bold;
    top: 268px;
    right: 47px;
    position: relative;
    z-index: 9999999999;
}