.bsic {
  display: flex;
  width: 720px;
  margin: auto;
  padding-top: 10px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
}

.bsic:hover {
  box-shadow: 0px 2px 5px 1px #BEC7C6;
  border-radius: 10px;
}

.bsic-mobile {
  width: 96%;
  display: flex;
  height: 75px;
  margin: auto;
  padding-top: 10px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: space-around;
}

.pid {
  height: 71px;
  width: 720px;
  justify-content: center;
  margin: auto;
  position: relative;
  top: 6px;
  z-index: 200;
}

.pid-mobile {
  height: 77px;
  width: 100%;
  top: 10px;
}

.line {
  position: relative;
  width: 720px;
  margin: auto;
}

.users-name-con {
  position: relative;
}

.users-space {
  width: 25%;
  position: relative;
  top: 6.5px;
}

.user-anon-button-text {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  color: #FFFFFF;
}



.users {
  display: flex;
  position: relative;
  width: 50%;
  height: 0px;
  mix-blend-mode: normal;
  margin: auto;
}

.users-search {
  position: relative;
  top: 2px;
  height: 40px;
  width: 724px;
  padding-top: 10px;
  overflow-y: scroll;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  border: 0.5px solid #38A09D;
  border-radius: 8px;
  box-sizing: border-box;
  z-index: 1000;
  background-color: white;
}

.users-search:hover {
  box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.users-search-mobile {
  width: 96%;
}

.users-search-input {
  width: 580px;
  height: 30px;
  border: white;
  right: 20px;
  position: absolute;
  top: 3px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 31px;
}

.users-search-input-mobile {
  width: 80%;
}

input:focus {
  outline: none;
}

.users-search-circle {
  position: absolute;
  left: 2%;
}

.users-search-row {
  position: absolute;
  left: 3.6%;
  top: 37%;
}

.menu-icon-r {
  position: relative;
  right: 9px;
  width: 95px;
}

.menu-icon-r-mobile {
  width: 70px;
}

.users-name {
  position: relative;
  width: 350px;
  height: 26px;
  margin: auto;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 120%;
  line-height: 31px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #494949;
}

.users-name-mobile {
  width: 180px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: right;
}

.users-book-page-header-down-line {
  position: relative;
  top: 2.5px;
}

.users-book-page-header-down-line-mobile {
  position: relative;
  top: -4.5px;
  width: 7px;
}

.users-followers {
  position: relative;
  height: 26px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
  mix-blend-mode: normal;
  cursor: pointer;

}

.user-anon-container {
  display: flex;
  justify-content: center;
  top: 266px;
  width: 100%;
  position: fixed;
  z-index: 11111;
  height: 216px;
}

.user-anon-on {
  position: relative;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  z-index: 9999999;
  box-sizing: border-box;
  border-radius: 14px;
  display: flex;
  width: 750px;
}

.user-anon-svg {
  align-items: center;
  position: relative;
  right: 70px;
}

.user-anon-text {
  align-items: center;
  position: relative;
  right: 100px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
}

.user-anon-svg-and-text {
  align-items: center;
  position: relative;
  display: flex;
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
}

.users-followers-text:hover {
  background-color: #e4e8e8;
  border-radius: 30px;
  width: 86px;
  position: relative;
  left: 5px;
}

.user-anon-button {
  align-items: center;
  position: relative;
  display: flex;
  width: 155.42px;
  height: 37px;
  position: relative;
  background: #38A09D;
  border-radius: 8px;
  cursor: pointer;
  top: 165px;
}

.user-anon-button:hover {
  background: #38a3a0;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.sjsjsj {
  position: relative;
  display: flex;
  width: 150px;
  justify-content: space-between;
}

.users-followers-mobile {
  width: 89.67px;
  height: 12.1px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  right: 0px;
}

.users-message {
  display: flex;
  width: 155.42px;
  height: 37px;
  position: relative;
  background: #38A09D;
  border-radius: 8px;
  cursor: pointer;
}

.users-message:hover {
  background: #38a3a0;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.users-message-mobile {
  display: flex;
  width: 155.42px;
  height: 37px;
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
  width: 96px;
  height: 20px;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  top: 5px;
}

a:-webkit-any-link {
  text-decoration: blink !important;
  color: #38A09D;
}

.users-message-text {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  margin: auto;
  display: flex;
  align-items: center;
  text-align: center;
  padding-right: 10px;
  color: #FFFFFF;
}

.users-message-text-mobile {
  height: 14px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
}

.users-email {
  width: 0px;
  padding-top: 8px;
  padding-right: 15px;
}

.users-email-mobile {
  width: 0px;
  padding-top: 0px;
  padding-right: 5px;
  position: relative;
  top: -5px;
}

.users-follow {
  position: relative;
  width: 93.25px;
  height: 37px;
  background: #FFFFFF;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  right: -10px;
  top: 6.5px;
  cursor: pointer;
}

.users-follow:hover {
  box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.users-follow-mobile {
  width: 96px;
  height: 17px;
  background: #38A09D;
  border-radius: 8px;
  right: 0px;
  top: 0px;
  background: #FFFFFF;
  border: 1px solid #38A09D;
}

.users-follow-no {
  position: relative;
  width: 93.25px;
  height: 37px;
  background: #38A09D;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  right: -10px;
  top: 6.5px;
}

.users-follow-no:hover {
  background: #38a3a0;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.users-follow-no-mobile {
  width: 96px;
  height: 17px;
  background: #38A09D;
  border-radius: 8px;
}

.users-follow-text {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #38A09D;
  padding-right: 25%;
  padding-top: 5%;
  cursor: pointer;
}

.users-follow-text-mobile {
  color: #38A09D;
  position: relative;
  width: 100%;
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.users-follow-text-no {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  padding-right: 25%;
  padding-top: 5%;
  cursor: pointer;
}

.users-follow-text-no-mobile {
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #F4F2F2;
  position: relative;
  justify-content: center;
}

.backHistory {
  position: relative;
  right: 20px;
  width: 100px;
}

/*
 ///////////////////////////////////////////////////////////////////////////////////////
 new new                    
*/

.header-openPid-s {
  position: absolute;
  width: 100%;
  height: 70vh;
  background-color: white;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  display: block;
  top: 114px;
  overflow: auto;
  z-index: 150;
  justify-content: center;
}

.header-openPidD {
  width: 100%;
  background-color: white;
  overflow-y: scroll;
}

.iframe-pid {
  height: calc(100% - 0px);
  width: 100%;
  position: relative;
}

.openPid-main-container {
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;

}

.openPid-top-item-background-selecte {
  color: #38A09D;
  font-family: Heebo;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  cursor: pointer;

  border-radius: 23.1px;
  background-color: #e4e4e4;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
}

.openPid-top-item-background {

  font-family: Heebo;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #151515;
  cursor: pointer;
  border-radius: 23.1px;
  background-color: #e4e4e4;
  margin-right: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
}

.openPid-scroll {
  position: relative;
}

.openPid-scroll::-webkit-scrollbar {
  width: 9px;
  height: 1px;
  background-color: #e5e7e7;
}

.openPid-scroll::-webkit-scrollbar-thumb {
  background: #798383;
}