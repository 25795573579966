.footer-mobile {
    position: fixed;
    display: flex;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
    justify-content: space-between;
    z-index: 99999;
    bottom: 0;
}

.footer-mobile-Notification {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    right: 10px;
}

.footer-mobile-Notification-w {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #B4CCCB;
    right: 10px;
}

.footer-mobile-menu {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    left: 10px;
}

.footer-mobile-logo {
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
}

.ccccc {
    color: #B4CCCB;
}

.none {
    display: none;
}