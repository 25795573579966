.MuiToolbar-regular {
  right: 220px;
  width: 410px;
  justify-content: space-between;
}

.openPid-main-container {
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: center;
 
}





@media screen and (max-width: 850px) {
  .MuiToolbar-regular {
    right: 0px;
  }
}

@media screen and (max-width: 450px) {
  .MuiToolbar-regular {
    right: 0px;
  }
}