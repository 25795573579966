.form-page-connect-forget {
    position: relative;
    width: 298px;
    height: 37px;
    right: 20px;
    top: 40px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
}

.form-page-connect-forget-text {
    position: relative;
    top: 3px;
    right: 95px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.form-page-connect-forget-error {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    color: red;
}