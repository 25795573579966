.form-page-logo {
    position: relative;
    right: 40px;
    top: 40px;
    width: 100px;
    height: 60px;
}

.form-page-logo-word {
    position: relative;
    right: 40px;
    top: 50px;
    width: 100px;
    height: 150px;
}

.form-page-username {
    position: relative;
    width: 297px;
    height: 37px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #656565;
}

.form-page-connect-first-name {
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}

.form-page-connect-first-name:hover {
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.form-page-password:hover {
    box-shadow: 0.5px 1.5px 4px rgb(0 0 0 / 25%);
}

.form-page-connect-first-name-yoma {
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
    top: 25px;
}

.form-page-connect-first-name-yoma-mobile {
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}

.form-page-connect-first-name-input {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    right: 14px;
    outline: none;
    border-color: unset;
    border-width: 0px;
    border-style: inset;
    top: 6px;
}

.form-page-connect-text {
    position: relative;
    top: 3px;
    right: 120px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.form-page-no-account {
    position: relative;
    width: 298px;
    height: 37px;
    right: 85px;
    top: 35px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
}

.form-page-visitor {
    position: relative;
    right: 120px;
    top: 50px;
    height: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
}

.form-page-connect-error {
    position: relative;
    width: 300px;
    right: 24px;
    top: 20px;
    height: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ed3636;
    cursor: pointer;
}