.header-moblie {
    position: fixed;
    width: 100%;
    height: 63px;
    background: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    z-index: 99999;
    display: flex;
    justify-content: space-between;
}

.header-moblie-menu {
    position: relative;
    top: 10px;
    left: 10px;
    color: #38A09D;
}

.header-moblie-logo {
    position: relative;
    right: 8px;
    top: 10px;
}

.header-moblie-Notification {
    position: relative;
    right: 8px;
    top: 10px;
}

.none {
    display: none;
}