.center {
    display: none;
}

.mw-gallery-packed-overlay {
    display: flex;
    text-align: center;
    right: 39px;
    position: relative;
}

img {
    width: 20px;
    height: 20px;
    position: relative;
}

hr {
    position: relative;
    width: 950px;
    margin: auto;
    stroke: rgb(244, 242, 242);
}

.home-font {
    font-family: Heebo;
    font-style: normal;
    font-size: 30px;
    line-height: 56px;
    text-align: -webkit-center;
    position: relative;
}

.home-font-mobile {
    font-family: Heebo;
    font-style: normal;
    font-size: 30px;
    line-height: 56px;
    text-align: -webkit-center;
    position: relative;
}

.qwqwq {
    background-color: #f4f4f3;
    position: relative;
}

.qwqw {
    right: -5%;
    position: relative;
}

.w {
    width: 84%;
}

.home-work {
    font-family: Heebo;
    font-style: normal;
    font-size: 20px;
    line-height: 85px;
}

.home-work-sub {
    font-size: 18px;
    line-height: 35px;
    top: 10px;
    position: relative;
}

li {
    list-style: none;
    padding-right: 0em;
}

.gallerybox {
    display: block;
}

.welcome {
    position: relative;
    top: 100px;
    right: 10%;
    font-family: Heebo;
    font-style: normal;
    font-weight: 900;
    font-size: 58px;
    line-height: 85px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #494949;
}

.welcome-landingPage {
    position: relative;
    font-family: Heebo;
    width: 100%;
    height: 100%;
    top: 30%;
    font-style: normal;
    font-weight: 900;
    font-size: 58px;
    align-items: center;
    text-align: center;
    color: #494949;
}

.flex-im {
    position: absolute;
    display: flex;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    background: linear-gradient(rgba(255, 255, 255, .5), rgba(255, 255, 255, .5)), url(../../svg/back.jpeg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    top: 58px;
}

.flex-im-b {
    position: absolute;
    display: flex;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-color: white;
}

.mmmm {
    height: 100%;
}

.welcome-a {
    position: relative;
    width: 643px;
    top: 100px;
    right: 10%;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 35px;
    line-height: 117.7%;

    text-align: justify;
    color: #494949;
}

.welcome-b {
    position: relative;
    width: 155px;
    top: 113px;
    right: 8.5%;
    height: 50px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 35px;
    align-items: center;
    text-align: center;
    color: #494949;
}

.welcome-b-main {
    display: flex;
    position: relative;
    right: 13px;
    width: 700px;
}


.login {
    position: absolute;
    left: 40px;
    opacity: 100 !important;
}

.landing-page-container {
    background-color: #ffffff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    position: relative;
}

.landing-page-btn-login {
    width: 168px;
    height: 46px;
    border-radius: 4px;
    box-shadow: 0 2px 10px -4px rgba(0, 0, 0, 0.5);
    background-color: #38A09D;
}

.flex {
    display: flex;
}

.fixed-b {
    width: 100%;
    z-index: 1;
    background-color: white;
}

.landing-page-btn-login-text {
    text-align: center;
    font-family: Heebo;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.13px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
    ;
}

.landing-page-logo-container {
    margin-bottom: 50px;
    position: relative;
}

.landing-page-gemara-logo-svg {
    position: relative;
    z-index: 100;
}

.landing-page-mihlol-logo {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
}

.welcome-connect-or-trial {
    position: relative;
    height: 105px;
    right: 11.5%;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
    text-align: right;
    color: #494949;
    justify-content: right;
}

.welcome-trial-to-regis {
    position: relative;
    width: 163px;
    height: 115px;
    right: 10px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
}

.welcome-connect-learn {
    position: relative;
    height: 307px;
    justify-content: center;
}

.welcome-connect-learn-title {
    position: relative;
    height: 115px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 117.7%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #494949;
    justify-content: center;
}

.welcome-connect-learn-button-main {
    display: flex;
    justify-content: center;
    position: relative;
}

.welcome-connect-learn-button {
    position: relative;
    width: 174px;
    height: 173px;
    background: #FFFFFF;
    border: 1px solid #E2DBDB;
    box-sizing: border-box;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 117.7%;
    align-items: center;
    text-align: center;
    color: #37A09D !important;
    cursor: pointer;
    margin: 5px;
}

.welcome-connect-learn-button:hover {
    -webkit-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.11);
}

.welcome-connect-learn-button-icon {
    position: relative;
    right: 3px;
    top: 30px;
    height: 130px;
}

.welcome-connect-learn-button-icon-b {
    position: relative;
    right: 3px;
    top: 25px;
    height: 130px;
}

@media screen and (max-height: 630px) {
    .landing-page-container {
        margin-top: 50px;
    }
}