.nav-arrow-button-left {
    position: relative;
    right: 15px;
    top: 14.5px;
    height: 25px;
}

.nav-arrow-button-left-close {
    height: 25px;
    width: 25px;
    cursor: pointer;
    padding-right: 8px;
    padding-top: 3px;
    border-radius: 3px;
    border: 1px;
    background-color: #e4e4e4;
}



.panel-expanded-header-close-sign :hover {
    background-color: #c7c6c6;
    transition: all 0.3s ease-in-out;
}

.back12 {
    padding-right: 10px;
    position: absolute;
    padding-top: 8px;
}

.section-content-hidushim {
    position: relative;
    height: calc(100% - 65px);
}

/*-------------------------------------------------------*/

.hidushim-line {
    position: relative;
    width: 90%;
    padding-right: 26px;
}

.hidushim-line-2 {
    top: 10px;
    position: relative;
    width: 90%;
    padding-right: 26px;
}


.hidushim-line-input {
    position: relative;
    width: 90%;
}

.hidushim {
    font-family: "Frank Ruhl Libre-regular";
    font-size: 19px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: 0.13px;
    text-align: justify;
    color: #050505;
    width: 85%;
    padding-right: 8%;
    justify-content: space-between;
}

.panel-plus-sign-svg-left-arrow {
    position: relative;
    right: 16px;
    top: 26.66%;
    height: 25px;
}

.panel-plus-sign-svg-left-arrow-open {
    position: relative;
    right: 18px;
    top: 14.5px;
}

.panel-plus-sign-svg-left-open {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: 0px;
    background: #37A09D;
    box-shadow: 0.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
}

.panel-plus-sign-svg-left-open-empty {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: 0px;
    background: #989e9e;
    box-shadow: 0.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    cursor: pointer;
    color: #FFFFFF;
    display: flex;
}

.panel-plus-sign-svg-left-open:hover {
    background: #369996;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.panel-plus-sign-svg-left {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: 8px;
    background: #37A09D;
    box-shadow: 1.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    color: #FFFFFF;
    display: flex;
}

.panel-plus-sign-svg-left-empty {
    position: absolute;
    width: 52px;
    height: 52px;
    top: 15px;
    right: 8px;
    background: #989e9e;
    box-shadow: 1.5px 1.5px 4px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    color: #FFFFFF;
    display: flex;

}

.panel-plus-sign-svg-left:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.panel-plus-sign-svg-left-text {
    top: 15px;
    position: relative;
    left: 7px;
    direction: ltr;
    text-align: center;
    font-size: 13px;
}

.book-page-panel-header-h {
    position: relative;
    top: 150px;
    right: 8px;
    width: 52px;
    height: 190px;
    opacity: 0.74;
    background: #FFFFFF;
    -webkit-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    cursor: pointer;
}

.book-page-panel-header-h:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.book-page-panel-header-h-open:hover {
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.book-page-panel-header-h-open {
    position: relative;
    top: 150px;
    width: 52px;
    height: 190px;
    background: #FFFFFF;
    -webkit-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 0.5px 1.5px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.book-page-panel-header-h-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    transform: rotate(90deg);
    justify-content: center;
    height: 100%;
}

.book-page-panel-header-h-number-l {
    position: absolute;
    display: flex;
    width: 31px;
    height: 33px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #38A09D;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    top: 150px;
    right: 14px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #DBE2DE;
}

.book-page-panel-header-h-number-r {
    position: absolute;
    display: flex;
    width: 31px;
    height: 33px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #38A09D;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    top: 150px;
    right: 17px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #DBE2DE;
}

.book-page-panel-header-h-text-open {
    position: absolute;
    top: 65px;
    right: -15px;
    font-family: Heebo;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    transform: rotate(90deg);
}

.header-not-expanded-h-open {
    position: absolute;
    left: 399px;
}

.header-not-expanded-h {
    position: absolute;
}

.wi {
    width: 54px;
}