.menu-profile-mobile {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    top: 100px;
}

.menu-icon-mobile {
    position: relative;
    display: flex;
    justify-content: center;
    right: 6px;
}

.menu-name-mobile {
    position: relative;
    width: 100%;
    height: 62px;
    top: 5px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    align-items: center;
    text-align: center;
    color: #000000;
}

.menu-my-chidush-Follower-mobile {
    position: relative;
    width: 249px;
    height: 55px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    color: #38A09D;
}

.menu-line-mobile {
    position: relative;
    width: 212px;
    height: 0px;
    border: 1px solid #B4CCCB;
    right: 20px;
}

.menu-my-chidush-mobile {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-size: 18px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    top: 10px;
    height: 40px;
}

.menu-profile {
    position: absolute;
    width: 177px;
    height: 253px;
    left: 29px;
    top: 50px;
    background: #FFFFFF;
    border: 1px solid #E2DBDB;
    box-sizing: border-box;
    box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
    z-index: 200;
}

.menu-icon {
    position: relative;
    right: 60px;
    padding-top: 4.81%;
}

.menu-name {
    position: relative;
    height: 37px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000000;
}

.menu-line-1 {
    position: relative;
    width: 127px;
    height: 0px;
    right: 22px;
    top: 10px;
    border: 1px solid #38A09D;
}

.menu-my-chidush {
    position: relative;
    width: 100%;
    height: 55px;
    justify-content: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    cursor: pointer;
}

.menu-my-chidush-text {
    border-radius: 5px;
    width: 128px;
    line-height: 40px;
    top: 5px;
    position: relative;
}

.menu-my-chidush-text:hover {
    background-color: #e4e8e8;

}



.menu-line-2 {
    position: relative;
    width: 127px;
    height: 0px;
    right: 22px;
    border: 1px solid #B7C0C0;
}

.menu-settings {
    position: relative;
    width: 100%;
    height: 37px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    justify-content: center;
}

.menu-profile-none {
    display: none;
}

.menu-line-3 {
    position: relative;
    width: 127px;
    height: 0px;
    right: 22px;
    border: 1px solid #B7C0C0;
}

.menu-disconnect {
    position: relative;
    justify-content: center;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
    cursor: pointer;
    height: 40px;
}

.menu-my-chidush-Follower {
    position: relative;
    width: 100%;
    height: 39px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #38A09D;
}