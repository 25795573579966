.search-main-list {
    top: 50px;
    width: 100%;
    height: 100%;
    justify-content: center;
    position: absolute;
    background-color: #ebebebea;
    overflow: auto;
}

.search-main-list::-webkit-scrollbar {
    width: 15px;
    height: 1px;
    background-color: #e5e7e7;
}

.search-main-list::-webkit-scrollbar-thumb {
    background: #798383;

}

.search-main-item {
    width: 728px;
    min-height: 123px;
    height: auto;
    margin: 2px;
    position: relative;
    display: flex;
    justify-content: center;
}


.search-result-title {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #38A09D;
    width: 330px;
    position: relative;
    cursor: pointer;
    right: 20px;
    height: 40px;
    top: -2.5px;
}

.search-result-title-2 {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-items: end;
    color: #38A09D;
    width: 330px;
    position: relative;
    cursor: pointer;
    right: 20px;
    height: 40px;
    top: -2.5px;
    justify-content: flex-end;
}

.search-result-title-main {
    display: flex;
    position: relative;
}

.search-line {
    position: relative;
    width: 700px;
    top: -3px;
    height: 0.5px;
    background: #DBE2DE;
}


.search-result-body {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    align-items: center;
    text-align: right;
    color: #494949;
    width: 660px;
    position: relative;
    margin-top: 13px;
    margin-bottom: 13px;
    right: 20px;
}

.search-main-item-list {
    position: relative;
    width: 100%;
    justify-content: center;
    display: flex;
    top: 40px;
   
}

.search-search {
    position: fixed;
    min-height: 40px;
    width: 700px;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    z-index: 1001;
    background-color: #fff;
}

.search-input-main {
    position: relative;
    display: flex;
}

.search-search-input {
    border: white;
    right: 20px;
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    width: 600px;
    background-color: #fff;
    height: 40px;
}

.search-circle {
    position: absolute;
    align-items: center;
    cursor: pointer;
    height: 42px;
    display: flex;
    left: 10px;
}


.search-search-main {
    position: relative;
    justify-content: center;
    display: flex;
}

.search-search-main-top {
    position: relative;
    top: 10px;
}

.search-search-main-back {
    position: fixed;
    height: 10px;
    top: 50px;
    z-index: 10;
    width: 688px;
    background-color: #fcfcfc;
}


.search-total-results {
    width: 660px;
    position: relative;
    color: #494949;
    background-color: #ebebebea;
    opacity: 0.9;
    text-align: right;
    font-family: Heebo;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    height: 45px;
    display: flex;
    align-items: center;
    white-space: pre;
    cursor: pointer;
}

.search-total-results-valueSearch {
    color: #38A09D;
    font-family: Heebo;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    height: 45px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-total-results-main {
    position: relative;
    width: 100%;
    justify-content: center;
    top: 40px;
    display: flex;
}


.search-total-results-number-main {
    position: relative;
    width: 660px;
    display: flex;
}

.search-total-results-number-res {
    color: var(--, #494949);
    text-align: center;
    font-family: Heebo;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    position: relative;
}

.search-total-results-number {
    width: 180px;
    position: relative;
    color: #494949;
    background-color: #ebebebea;
    opacity: 0.9;
    text-align: right;
    color: var(--, #494949);
    text-align: center;
    font-family: Heebo;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    white-space: pre;
    left: 10px;
    cursor: pointer;
}

.search-total-results-number-2 {
    width: 200px;
    position: relative;
    color: #494949;
    background-color: #ebebebea;
    opacity: 0.9;
    text-align: right;
    color: var(--, #494949);
    text-align: center;
    font-family: Heebo;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    white-space: pre;
    cursor: pointer;
}

.border-bottom {
    border-bottom: 5px solid #38a09d;
}

.top {
    position: relative;
    top: -3px;
}

.result-list {
    position: relative;
    display: grid;
    border-radius: 8px;
    background-color: #fff;
}

.result-list-item {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;
}

.result-list-item-text {
    position: relative;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 31px;
    width: 90%;
    right: 3%;
}

.result-list-item:hover {
    background-color: #e4e8e8;
    border-radius: 8px;
    position: relative;
}