.users-follow-no-on-line {
    position: relative;
    width: 93.25px;
    height: 37px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 4.5px;
    right: 100px;
}

.ssssss {
    z-index: 200;
}

.on-line-party-follow-no-on-line {
    position: relative;
    width: 161.25px;
    height: 37px;
    background: #38A09D;
    border: 1px solid #38A09D;
    box-sizing: border-box;
    border-radius: 8px;
    top: 4.5px;
    right: 100px;
}

.on-line-party-follow-no-on-line-text {
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding-right: 25%;
    cursor: pointer;
}

.pid1 {
    height: 77px;
    width: 720px;
    justify-content: center;
    margin: auto;
    position: relative;
    top: 15px;
  }

  .bsic1 {
    display: flex;
    width: 720px;
    height: 100%;
    margin: auto;
    padding-top: 10px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 10px;
}


.on-line-party-users-name{
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}