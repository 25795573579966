.loading-indicator-container{
    opacity: 0.4;
    position: relative;
    z-index: 1;
}

.loading-wrapper{
    pointer-events: none;
}

.loading-indicator-middle-container{
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    flex-direction: row;
    justify-content: center;
    padding: 30px 0;
}

.loading-indicator-loadingsvg{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: scale(3);
} 

.loading-indicator-gmara{
    position: absolute;
    transform: scale(0.3);
}

