@font-face {
    font-display: swap;
    font-family: 'Mekorot-Vilna';
    src: url("./fonts/Vilna/Mekorot-Vilna.ttf")
}

@font-face {
    font-display: swap;
    font-family: 'Mekorot-Rashi';
    src: url("./fonts/Rashi/Mekorot-Rashi.ttf")
}

@font-face {
    font-display: swap;
    font-family: 'Frank Ruhl Libre-regular';
    src: url("./fonts/Frank_Ruhi_Libre/FrankRuhlLibre-Regular.ttf")
}

@font-face {
    font-display: swap;
    font-family: 'Frank Ruhl Libre-Medium';
    src: url("./fonts/Frank_Ruhi_Libre/FrankRuhlLibre-Medium.ttf")
}

@font-face {
    font-display: swap;
    font-family: 'PFT_Rashi Light';
    src: url("./fonts/new font/PFT_Rashi Light.ttf")
}

@font-face {
    font-display: swap;
    font-family: 'PFT_Rashi';
    src: url("./fonts/new font/PFT_Rashi.ttf")
}

@font-face {
    font-family: 'PFT_vilna Bold';
    font-display: swap;
    src: url("./fonts/new font/PFT_vilna Bold.ttf")
}

@font-face {
    font-family: 'PFT_vilna';
    font-display: swap;
    src: url("./fonts/new font/PFT_vilna.ttf")
}

/* alef-regular - hebrew */
@font-face {
    font-display: swap;
    font-family: 'Alef';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/alefGoogle/alef-v12-hebrew-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/alefGoogle/alef-v12-hebrew-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* alef-700 - hebrew */
@font-face {
    font-family: 'Alef';
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('./fonts/alefGoogle/alef-v12-hebrew-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/alefGoogle/alef-v12-hebrew-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-regular - hebrew */
@font-face {
    font-display: swap;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/heebo-v15-hebrew-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/heebo-v15-hebrew-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-500 - hebrew */
@font-face {
    font-display: swap;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('./fonts/heebo-v15-hebrew-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/heebo-v15-hebrew-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-600 - hebrew */
@font-face {
    font-display: swap;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 600;
    src: local(''),
        url('./fonts/heebo-v15-hebrew-600.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/heebo-v15-hebrew-600.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-700 - hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
        url('./fonts/heebo-v15-hebrew-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/heebo-v15-hebrew-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-300italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-300italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700 - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-700italic - vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
    font-display: swap;
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: local(''),
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('./fonts/roboto-v29-vietnamese_latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700italic.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.App {
    direction: rtl;
    background-color: #ffffff;
}

.rrrrrrrrr {
    position: fixed;
    right: 20px;
    z-index: 22222222;
    cursor: pointer;
    bottom: 14px;
}

.rrrrrrrrr-CloseSign {
    position: fixed;
    z-index: 22222222;
    cursor: pointer;
    top: 46px;
    left: 511px;
}

.fixed1 {
    position: fixed;
    display: flex;
    background-color: #bababa97;
    width: 100%;
    height: 40px;
    bottom: 0;
    color: #3e3e3e;
    font-family: Heebo;
    font-style: normal;
    line-height: 40px;
    font-size: 15px;
    justify-content: center;
    text-align: center;
    z-index: 111111;
}

::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}


.infinite-scroll-component {
    height: unset !important;
    overflow: unset !important;
}

/* Optional: show position indicator in red */

::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.text-align-center {
    text-align: center;
    word-spacing: 7px;
}

.loading-container {
    transform: scale(2);
    display: flex;
    justify-content: center;
    margin-top: 200px;
    align-items: center;
}

.tttttttt {
    position: relative;
    width: 93.25px;
    height: 29px;
    border: 1px solid #38a09d;
    box-sizing: border-box;
    border-radius: 8px;
    right: 10px;
    line-height: 29px;
    cursor: pointer;
    top: 5px;
}

.refLink {
    font-size: 14px;
    position: relative;
    top: -1px;
}

.app-advertising-yoma-mobile {
    position: fixed;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 19%);
    background-color: #fff;
    z-index: 9999999;
    box-sizing: border-box;
    border-radius: 24px;
    width: 70%;
    top: 220px;
    right: 15%;
    font-size: 83.1%;
}

.app-advertising-yoma {
    height: 90%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 60px;
    width: 100%;

}

.app-advertising-yoma-img {
    position: fixed;
    width: 27%;
    justify-content: center;
    height: auto;
    left: 35%;
    border-radius: 24px;
}

.onkeyEd-onClick {
    position: fixed;
    width: 100%;
    z-index: 999999;
}

.context-menu {
    width: 210px;
    min-height: 55px;
    max-height: 200px;
    height: auto;
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}



.context-menu-item {
    position: relative;
    min-height: 51px;
    display: flex;
    align-items: center;
    color: #040000;
    cursor: pointer;
    font-size: 14px;
    right: 5px;
    width: 95%;
}

.context-menu-item:hover {
    background-color: #e4e8e8;
    border-radius: 5px;
}