.form-page-main {
    position: relative;
    width: 351px;
    height: 489px;
    background: #ffffff;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    top: 120px;
}

.txtx {
    top: 0px;
    right: 15px;
}

.form-page-main-popup {
    position: relative;
    height: 489px;
    width: 330px;
}

.form-page-main-popup-mobile {
    position: relative;
    height: 330px;
    width: 334px;
    z-index: 100;
    background: #ffffff;
    border-radius: 14px;
    top: 100px;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 19%);
}

.form-page-main-popup-create {
    position: relative;
    height: 489px;
    right: 40px;
}

.form-page-logo {
    position: relative;
    right: 40px;
    top: 40px;
    width: 100px;
    height: 60px;
}

.form-page-logo-word {
    position: relative;
    right: 40px;
    top: 50px;
    width: 100px;
    height: 150px;
}

.form-page-username {
    position: relative;
    width: 297px;
    height: 37px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #656565;
}

.form-page-password {
    top: 15px;
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}

.form-page-password-yoma {
    top: 35px;
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}

.form-page-password-yoma-mobile {
    position: relative;
    width: 297px;
    height: 33.5px;
    right: 18px;
    border: .85px solid #38A09D;
    border-radius: 8px;
    margin: 5px;
}

.form-page-forget {
    position: relative;
    right: 100px;
    top: 30px;
    height: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
}



.form-page-connect:hover {
    background: #38a3a0;
    -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.form-page-connect-yoma {
    position: relative;
    width: 298px;
    height: 37px;
    right: 20px;
    top: 95px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
}

.form-page-connect-yoma-mobile {
    position: relative;
    width: 298px;
    height: 37px;
    right: 20px;
    top: 20px;
    background: #38A09D;
    border-radius: 8px;
    cursor: pointer;
}

.form-page-connect-text {
    position: relative;
    top: 3px;
    right: 120px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.form-page-line {
    position: relative;
    width: 298px;
    right: 20px;
    top: 35px;
}

.form-page-no-account {
    position: relative;
    width: 298px;
    height: 37px;
    right: 85px;
    top: 35px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 31px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #656565;
}

.form-page-visitor {
    position: relative;
    right: 120px;
    top: 50px;
    height: 20px;
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #37A09D;
    cursor: pointer;
}